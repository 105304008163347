.create__role__modal {
  width: 950px !important;
  height: 850px !important;
  border-radius: 5px;
  font-family: var(--font-family);
  margin-bottom: 2rem;
}

.create__role__modal .ant-modal-content {
  height: inherit;
  width: 950px !important;
  position: sticky !important;
  border-radius: 5px;
  transform: translateY(-4rem);
  backface-visibility: hidden;
}

.create__role__modal .ant-modal-body {
  height: calc(100% - 114px);
  max-height: calc(100% - 114px);
}

.create__role__modal .oncreaterole-modal {
  padding: 0px 38px;
}
.create__role__modal .oncreaterole-modal .create-role-form .ant-form-item {
  margin-bottom: 10px;
}

.create__role__modal
  .oncreaterole-modal
  .create-role-form
  .ant-form-item-label
  > label.ant-form-item-required::before {
  display: none;
}

@media screen and (max-width: 1469px) {
  .create__role__modal .ant-modal-content {
    height: 700px;
    width: 950px !important;
    transform: translateY(-5rem);
    backface-visibility: hidden;
  }
}

@media screen and (max-width: 1269px) {
  .create__role__modal .ant-modal-content {
    height: 650px;
    width: 950px !important;
    transform: translateY(-5.5rem);
    backface-visibility: hidden;
  }
}

.create__role__modal .ant-modal-title {
  padding: 13px 38px;
}

.create__role__modal .ant-modal-header {
  /* padding: 0 40px 29px 20px !important;
  border-bottom: none; */
}

.create__role__modal-header .heading {
  font-family: var(--font-family-bold);
  font-size: 23px;
  margin-bottom: 10px;
  line-height: 1;
  color: #4a4a4a;
}

.create__role__modal-header h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px !important;
}

.create__role__modal-header p.headingText {
  color: #828ea9;
  font-size: 18px;
  margin-bottom: 10px;
  font-family: var(--font-family);
}

.create__role__modal .ant-modal-body {
  border: none;
}

.create-role-form label {
  color: #2e384d;
  letter-spacing: normal;
  font-weight: bold;
  font-size: 14px;
}

.create-role-form input {
  border: 1px solid rgba(216, 216, 216, 0.1);
  background: rgba(216, 216, 216, 0.2);
}
.create-role-form input:focus {
  border-color: #0052cc;
}
.create-role-form input::placeholder {
  color: #d8d8d8;
}

.permissions-container {
  height: 350px;
  /* display: flex;
  flex: 1;
  flex-direction: column; */
  width: 100%;
  overflow: auto;
  display: inline-grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

@media screen and (max-width: 1469px) {
  .permissions-container {
    height: 250px;
  }
}
@media screen and (max-width: 1269px) {
  .permissions-container {
    height: 200px;
  }
}

@keyframes blink {
  78% {
    transform: scale(1.5);
  }

  92% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.loading-permissions span:nth-child(1) {
  animation: blink linear infinite 1s;
}
.loading-permissions span:nth-child(2) {
  animation: blink linear infinite 2s;
}
.loading-permissions span:nth-child(3) {
  animation: blink linear infinite 3s;
}

.create-role-form .ant-checkbox-group-item + .ant-checkbox-group-item {
  display: block;
}
.permission-name {
  text-transform: capitalize;
  margin-bottom: 20px !important;
  font-size: 16px;
}
.create-role-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
}
.create-role-form .ant-checkbox-wrapper {
  margin-bottom: 17px;
  display: block;
}
.create-role-form .ant-checkbox-wrapper > span {
  color: #828ea9;
  font-weight: normal;
  text-transform: capitalize;
}
.create-role-form .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.create-role-form .form-group.buttons {
  margin-top: 2rem;
}

.create-role-form input {
  border: 1px solid rgba(216, 216, 216, 0.1) !important;
  background: rgba(216, 216, 216, 0.2) !important;
  height: 41px;
}
.create-role-form input:focus {
  border-color: #0052cc !important;
}
.create-role-form input::placeholder {
  color: #d8d8d8;
}

.create-role-form .save__form {
  background: #007ace;
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
}
.save__form:hover {
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
}
.create-role-form .cancel-btn {
  margin-left: 10px;
  background: transparent;
  color: #005bab;
  border-radius: 2px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1.5rem;
}
.create-role-form .cancel-btn {
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
}
.error-text {
  color: red;
}
.permissions-container h3 {
  /* margin: 1rem 0; */
  font-weight: bold;
  font-family: var(--font-family-bold);
}

.permissions__name > span {
  font-size: 12px;
}

.create-role-form .ant-form-item-label label::before {
  display: none;
}
