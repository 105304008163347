.flex-column-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.search__states__input .ant-input-affix-wrapper {
  margin-bottom: 20px;
}

/* ADD STATE MODAL */
.add__state__modal {
  border-radius: 10px;
  height: 500px !important;
  font-family: var(--font-family);
}
.add__state__modal .ant-modal-content {
  border-radius: 10px;
  height: inherit;
}
.add__state__modal .ant-modal-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
}
.state__lookup__form .ant-form-item-required {
  /* font-weight: 600; */
}
.state__lookup__form .ant-form-item-required::before {
  display: none !important;
}

/* EDIT STATE MODAL */
.edit__state {
  height: 450px !important;
  width: 700px !important;
}
.edit__state .ant-modal-content {
  width: inherit;
  height: inherit;
  position: sticky !important;
  border-radius: 13px;
  font-family: var(--font-family);
}
.edit__state .ant-modal-header {
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  padding: 1rem 1.5rem;
}
.edit__state .ant-modal-title {
  font-size: 18px;
  font-family: var(--font-family-bold);
}

.edit__state-form .ant-form-item-label label {
  /* font-weight: 600; */
}

.edit__state-form .ant-form-item-required::before {
  display: none !important;
}

.state__lookup__row td:nth-child(5) {
  text-align: right;
}

.transparent__bg {
  background: transparent !important;
}

.state__lookup__form .ant-form-item,
.edit__state-form .ant-form-item {
  margin-bottom: unset;
}

.header-span100 .ant-col .ant-row {
  width: 100%;
}

.add__state__modal .ant-modal-content,
.edit__state .ant-modal-content {
  width: 700px;
  border-radius: 5px;
  /* height: 400px; */
  height: fit-content;
}

.state__lookup__form .ant-form-item-explain,
.edit__state-form .ant-form-item-explain {
  text-align: left;
}
