@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed|Montserrat);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap);
.signup__form {
    font-family: var(--font-family);
    margin-bottom: 4rem;
}
.form__header {
    margin-bottom: 40px;
    /* font-family: var(--font-family-bold); */
}
.form__header h1 {
    font-size: 36px;
}
.form__header p {
    color: #828ea9;
    font-size: 16px;
}
.error-message {
    color: red;
    font-weight: 700;
    font-size: 12px;
}
.signup__form .ant-form-item-label > label {
    color: #5a7184;
    font-weight: 600;
    font-size: 15px;
}
.form-group.exempt label {
    font-weight: 600;
    font-family: var(--font-family);
}
.signup__form .ant-form-item-required::before {
    display: none !important;
}
.signup__form .ant-form-item-explain {
    margin-top: 0;
}
.signup__form .ant-input-group {
    /* border: 1px solid #c3cad9 !important; */
    border-radius: 5px !important;
    width: 100%;
    -webkit-transform: none;
            transform: none;
    height: 41px;
}

.signup__form .ant-input-group .ant-input-group-addon {
    background: transparent;
}

.phonenumber__input .ant-input-group-addon:first-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.phonenumber__input .ant-input {
    height: 41px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.signup__input {
    height: 41px;
}
/* .ant-input-number {
    height: 56px;
    display: block;
    width: 100%;
    padding: 12px 20px;
    border-radius: 8px;
} */

.ant-input-password.signup__input {
    max-height: 41px;
}
.ant-input-affix-wrapper.ant-input-password.signup__input {
    border-radius: 5px;
}
.ant-input-password.signup__input > .ant-input {
    height: 35px;
}
.signup__input .ant-select-selector {
    height: 41px !important;
    border-radius: 5px !important;
}

/* CONFIRMATION MODAL */

.signup__modal {
    background: #fff;
    width: 600px !important;
    height: 400px;
    font-family: var(--font-family);
    text-align: center;
}

.signup__modal .ant-modal-content {
    height: inherit;
    width: inherit;
    position: -webkit-sticky !important;
    position: sticky !important;
}

.signup__modal h3 {
    font-size: 18px;
    margin-top: 2rem !important;
}
.signup__modal p {
    color: #828ea9;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2rem !important;
}
.signup__modal img {
    width: 200px;
}
.signup__modal .footer {
    width: 100%;
    text-align: center;
}

.signup__modal a {
    color: #0c5bab !important;
}
.loader-container {
    text-align: center;
}

/*** SIGNUP 404 ***/
.signup__notfound {
    background: #fff;
    font-family: var(--font-family);
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    text-align: center;
}
.signup__notfound h1 {
    font-family: var(--font-family-bold);
    color: #2e384d;
}
.signup__notfound p {
    color: #828ea9;
}
.form-group.signup__footer a {
    font-weight: 600;
    color: #0c5bab !important;
}
.form-group.signup__footer p {
    font-weight: 600;
}
/* .ant-checkbox + span {
    color: #828ea9;
    font-size: 14px;
}
.ant-checkbox-checked {
    &:after {
        border: 1px solid #0c5bab;
    }
}
.ant-checkbox-checked .ant-checkbox-inner {
    background: #0c5bab;
    border-color: #0c5bab;
}
.ant-input-number: focus, .ant-input-number-focused {
    border-color: #0c5bab;
    box-shadow: none;
}
.ant-input-affix-wrapper {
    &:hover {
        border-color: #0c5bab;
    }
    &:focus {
        border-color: $0c5bab;
        box-shadow: none !important;
        outline: none;
    }
}
.ant-input-suffix {
    display: flex;
    align-items: center;
}
.ant-input-password {
    border-radius: 8px;
} */

.forgot__password-form {
  font-family: var(--font-family);
}

.forgot__password-form .form-header {
  font-family: var(--font-family);
  margin-bottom: 37px;
}

.forgot__password-form .form-header h1 {
  font-family: var(--font-family-bold);
}

.forgot__password-form .error-message {
  color: red;
  font-weight: 700;
  font-size: 12px;
}
.forgot__password-form .ant-form-item-required {
  font-size: 14px;
  font-weight: 600;
}
.forgot__password-form .ant-form-item-required::before {
  display: none !important;
}
.forgot__password-form .email-input > input {
  border: 1px solid #c3cad9;
  height: 50px;
}
.forgot__password-form .email-input > input:focus {
  border: 1px solid #0c5bab;
}
.forgot__password-input {
  height: 41px;
}
.forgot__password-form button {
  width: 100%;
  border-radius: 8px;
}
.forgot__password-form a {
  color: #0c5bab !important;
}
.forgot__password-form a > i {
  margin-right: 4px;
}
.forgot__password-form .loader-container {
  text-align: center;
}

/* .forgotpassword__confirm-modal {
    background: #fff;
    width: 600px !important;
    height: 500px;
    font-family: "Akkurat";
}
.forgotpassword__confirm-modal .ant-modal-content {
    height: inherit;
    width: inherit;
    position: sticky !important;
}
.forgotpassword__confirm-modal .ant-modal-footer {
    border-top: none;
}

.forgotpassword__confirm-modal div {
    text-align: center;
}

.forgotpassword__confirm-modal h3 {
    font-size: 18px;
    margin-top: 2rem !important;
}
.forgotpassword__confirm-modal p {
    color: #828ea9;
    font-size: 14px;
    margin-top: 2rem !important;
    font-weight: 500;
    margin-bottom: 2rem !important;
}
.forgotpassword__confirm-modal .message {
    text-transform: capitalize;
}
.forgotpassword__confirm-modal img {
    width: 200px;
}
.forgotpassword__confirm-modal .footer {
    width: 100%;
    text-align: center;
} */
.text-right {
  text-align: right;
}

.reset__password-form {
    font-family: var(--font-family);
}

.reset__password-form .form-header {
    margin-bottom: 40px !important;
}
.reset__password-form h1 {
    font-size: 36px;
}
.reset__password-form p {
    color: #828ea9;
    font-size: 16px;
}
.text-center {
    text-align: center;
}
.reset__password-form .form-group {
    margin-bottom: 20px !important;
}
.reset__password-form .form-group label {
    color: #5a7184;
    font-size: 16px;
}
.error-text {
    color: red;
    font-size: 12px;
    font-weight: 500;
}
.reset__password-form button {
    border-radius: 8px;
    font-weight: 500;
    /* margin-top: 50px; */
}
.reset__password-form .ant-input-password {
    height: 50px;
    border: solid 1px #c3cad9;
    background-color: #ffffff;
    padding: 18px 20px;
}
.reset__password-form .ant-input-affix-wrapper {
    max-height: 50px;
}
.reset__password-form .ant-input-password > .ant-input {
    margin-top: -14px;
    height: 40px;
}
.reset__password-form .ant-input-affix-wrapper:hover {
    border-color: #0c5bab;
}
.reset__password-form .ant-input-affix-wrapper :focus {
    border-color: #0c5bab;
    box-shadow: none !important;
    outline: none;
}
.ant-input-affix-wrapper .ant-input-suffix {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.ant-input-affix-wrapper.ant-input-password {
    border-radius: 8px;
}

/* RESET MODAL */
.reset__modal {
    background: #fff;
    width: 600px !important;
    height: 400px;
    font-family: var(--font-family);
    text-align: center;
}

.reset__modal .ant-modal-content {
    height: inherit;
    width: inherit;
    position: -webkit-sticky !important;
    position: sticky !important;
}

.reset__modal h3 {
    font-size: 18px;
    margin-top: 2rem !important;
}
.reset__modal p {
    color: #828ea9;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2rem !important;
}
.reset__modal img {
    width: 200px;
}
.reset__modal .footer {
    width: 100%;
    text-align: center;
}
.reset__modal button {
    padding: 0 40px;
    height: 40px;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

p {
  margin-bottom: unset !important;
}

.main-page__body {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.active,
.not-active {
  text-transform: capitalize;
  width: 64px;
  height: 16px;
  color: var(--active-green);
}

.qaqc,
.not-qaqc {
  text-transform: capitalize;
  width: 64px;
  height: 16px;
}

.not-active {
  color: var(--inactive-red);
}

.loading-outlined svg {
  width: 100%;
  margin: auto;
}

.hide {
  display: none;
}

.nocursor {
  cursor: not-allowed;
  border: none;
  opacity: 0.2;
  background-color: var(--no-cursor-card) !important;
  z-index: 100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nocursor .qtr-icon img {
  -webkit-filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1);
  filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1);
}

.opacity-7-10 {
  opacity: 0.5 !important;
}

.qtr-icon {
  margin-bottom: 0.4rem;
}

.qtr-icon img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin-top: 1rem;
}

.create-fac-btn button,
.create-user-btn button {
  margin-left: 0px !important;
  /* height: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white); */
}

.ant-modal-close {
  display: none;
}

.ant-modal-body {
  height: 100%;
  width: 100%;
}

#facilities .ant-steps-item-process .ant-steps-item-icon {
  background-color: #007ace;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 8px 14px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #393939;
}

.ant-dropdown-menu-item:hover {
  background-color: #f4f4f4;
}

.facility-header__main div.ant-select:not(:last-of-type) {
  margin-right: 10px;
}

.ant-select-item {
  font-size: 0.75em;
  padding: 2px 8px;
}

.ant-select-dropdown {
  font-weight: normal;
  font-family: var(--font-family);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-dropdown .ant-select-item.ant-select-item-option,
.ant-select-item.ant-select-item-option-content {
  font-weight: normal;
  font-family: var(--font-family);
  -webkit-align-items: center !important;
          align-items: center !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-item-option-content {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.ant-select-item.ant-select-item-option:not(:first-child) {
  font-weight: normal;
  font-family: var(--font-family);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-selection-placeholder {
  font-weight: normal !important;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 14.4px;
  font-weight: normal !important;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  /* line-height: 24px; */
  font-weight: normal !important;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  color: var(--dark);
}

.facility-header__main.reports-head {
  margin-bottom: unset !important;
}

.facility-header__main .ant-input,
.user__header__main .ant-input,
.user-header__main .ant-input {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.facility-header__main .ant-input-affix-wrapper,
.user__header__main .ant-input-affix-wrapper,
.user-header__main .ant-input-affix-wrapper {
  height: 32px;
  width: 254px;
}
.facility-header__main .ant-input-affix-wrapper > input.ant-input,
.user__header__main .ant-input-affix-wrapper > input.ant-input,
.user-header__main .ant-input-affix-wrapper > input.ant-input {
  padding-left: 1em !important;
}

.facility-header__main .ant-row-end {
  margin-left: 0px !important;
  margin-right: 0px;
}

.facility-header__main .ant-row-end .ant-col,
.user__header__main .ant-row-end .ant-col,
.user-header__main .ant-row-end .ant-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.facility-table ul.ant-table-pagination li.ant-pagination-item,
.facility-table ul.ant-table-pagination li.ant-pagination-prev,
.facility-table ul.ant-table-pagination li.ant-pagination-next,
.reports-table ul.ant-table-pagination li.ant-pagination-prev,
.reports-table ul.ant-table-pagination li.ant-pagination-next,
.reports-table ul.ant-table-pagination li.ant-pagination-item,
.users-table ul.ant-table-pagination li.ant-pagination-prev,
.users-table ul.ant-table-pagination li.ant-pagination-next,
.users-table ul.ant-table-pagination li.ant-pagination-item {
  height: 24px;
  width: 25px;
  line-height: 24px;
  border: none;

  /* line-height: 24px;
  width: 25px;
  height: 24px;
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a6872; */
}

.facility-table ul.ant-table-pagination .ant-pagination,
.users__tab ul.ant-table-pagination .ant-pagination,
.reports-table ul.ant-table-pagination .ant-pagination,
.users-table ul.ant-table-pagination .ant-pagination {
  font-size: 14px;
  border: none;
}

li.ant-pagination-item-active {
  background-color: #007ace !important;
  font-family: IBMPlexSans;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white) !important;
}

ul.ant-pagination li.ant-pagination-prev .ant-pagination-item-link,
ul.ant-pagination li.ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.ant-tree {
  font-size: 12px;
}

.ant-tree .ant-tree-treenode {
  font-size: 10px;
  font-weight: normal;
}

.facility-report__body .anticon-loading svg {
  width: 100%;
}

/* .ant-modal-content {
  zoom: 80%;
} */
/* .facility-report__standard .ant-modal-content {
  zoom: 80%;
} */

.facility-report__standard .ant-modal-content {
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  position: -webkit-sticky !important;
  position: sticky !important;
}

.facility-table .ant-table-body,
.reports-table .ant-modal-body {
  overflow: unset !important;
}

/* .facility-overflow-menu, .ant-dropdown-menu {

} */

.table-hash-count,
.reporting-form__heading span.table-hash-count {
  display: inline-block;
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.facility-report__standard .facility-report__header .report-title {
  /* letter-spacing: 2px; */
  font-family: var(--font-family-bold);
  font-size: 23px;
  /* font-size: 25px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #4a4a4a;
}

.facility-report__standard .facility-report__header .report-title,
.facility-report__standard .facility-report__header .report-subtitle-1,
.facility-report__standard .facility-report__header .report-date__title,
.facility-report__standard .facility-report__header .report-subtitle-2 {
  margin-bottom: 10px !important;
}

.facility-report__standard .facility-report__header .report-subtitle-1 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: right; */
  color: #9b9b9b;
}

.facility-report__standard .facility-report__header .report-subtitle-2 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: right; */
  color: #9b9b9b;
}

.facility-report__standard .facility-report__header .report-date__title {
  font-family: var(--font-family);
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  color: #4a4a4a;
}

.facility-report__standard .facility-report__header .report-time {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.thereport__body,
.facility-report__body:not(.onviewing-modal) {
  position: relative;
  margin-top: 10px;
  /* margin-top: 26px; */
  margin-bottom: 88px;
}
.facility-report__body.onviewing-modal {
  height: 100%;
}

@media screen and (max-width: 1469px) {
  .facility-report__body {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
}
.thereport__body .ant-card,
.facility-report__body .ant-card {
  width: 260px;
  height: 200px;
  border-radius: 5px;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  background-color: var(--white);

  opacity: 1;
  /* box-shadow: 0px 4px 2px -4px rgba(0, 0, 0, 0.4); */
  /* -webkit-box-shadow: 0px 4px 2px -4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 2px -4px rgba(0, 0, 0, 0.4); */
  background-color: var(--white);
}

@media screen and (max-width: 1469px) {
  .thereport__body .ant-card,
  .facility-report__body .ant-card {
    width: 11.2rem !important;
    height: 9.8rem !important;
    box-shadow: none;
  }
  .thereport__body div.ant-row,
  .facility-report__standard div.ant-row {
    margin-right: unset !important;
  }
}

.thereport__body .ant-card:not(.nocursor):hover,
.facility-report__body .ant-card:not(.nocursor):hover {
  box-shadow: -20px -15px -29px 8px rgba(67, 90, 111, 0.47);
  background-color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid #4a90e2;
}

.thereport__body > .ant-card-body,
.facility-report__body > .ant-card-body,
.facility-card__body > .ant-card-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  overflow: hidden;
  height: 100%;
}

.thereport__body .card-lock,
.facility-report__body .card-lock {
  width: 100%;
  text-align: right;
  text-align: -webkit-right;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.facility-report__body .qtr-lock-icon {
  width: 100%;
  text-align: right;
  text-align: -webkit-right;
}

.thereport__body .qtr-lock-icon,
.facility-report__body .qtr-lock-icon img {
  width: 22px;
  height: 24px;
}

@media screen and (max-width: 1469px) {
  .thereport__body .qtr-lock-icon,
  .facility-report__body .qtr-lock-icon img {
    width: 14px;
    height: 16px;
  }
  .qtr-text {
    font-size: 1em !important;
    margin-bottom: 0.4rem !important;
  }
  .qtr-icon img {
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
    margin-top: 0.8rem !important;
  }
  .qtr-month {
    font-family: var(--font-family);
    font-size: 1rem !important;
  }
}

.facility-card__body .card-content {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  justify-self: flex-end;
}

.onreporting-modal .facility-card__body .card-content {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: unset;
          justify-content: unset;
  justify-self: unset;
}

.qtr-text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-family: var(--font-family);
  line-height: 1.39;
  margin-bottom: 8px;
  /* margin-bottom: 0.4rem; */
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.qtr-month {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #9b9b9b;
}

.onreporting-modal .qtr-text {
  height: 26.4px;
  font-family: var(--font-family);
  line-height: 1.3;
  overflow: hidden;
}

.qtr-subtext {
  /* margin-bottom: 0.4rem; */
  /* opacity: 0.8; */
  font-family: var(--font-family);
  font-size: 11.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.facility-report__standard .ant-modal-header {
  padding: 0;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
}

.facility-report__standard .ant-modal-body {
  /* height: 14.4rem; */
  height: calc(100% - 126px);
  padding-top: 24px;
  padding-right: 38px;
  background-color: rgba(10, 10, 120, 0.05);
}

.facility-report__standard .ant-modal-title {
  padding: 13px 38px;
}

.thereport__body div.ant-row,
.facility-report__body:not(.onviewing-modal) div.ant-row {
  margin-right: unset;
  padding-left: 15px;
  margin-top: 50px;
}

.thereport__body img,
.facility-report__body img {
  vertical-align: unset;
}

.facility-report__body.onreporting-modal {
  margin-bottom: unset;
}

.facility-report__body.onreporting-modal .ant-card {
  width: 245px;
  height: 200px;
  border-radius: 5px;
}
.facility-report__body.onreporting-modal .qtr-subtext {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
}
.facility-report__body.onreporting-modal .qtr-text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: bold;
  line-height: 0.95;
  padding-bottom: 15px;
}
.facility-report__body.onreporting-modal .qtr-progress {
  padding-bottom: 29px;
}
.facility-report__body.onreporting-modal .qtr-icon {
  margin-bottom: 20px;
}
@media screen and (max-width: 1469px) {
  .facility-report__body.onreporting-modal .qtr-icon {
    margin-bottom: 5px !important;
  }
  .facility-report__body.onreporting-modal .qtr-progress {
    padding-bottom: 0px;
  }
}

.ant-modal-wrap {
  /* overflow: unset !important; */
}

.ant-result-title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
  margin: 16px;
  color: var(--result-title);
}

.ant-result-subtitle {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  margin: 16px;
  color: var(--result-subtitle);
}
.ant-result-title,
.ant-result-subtitle {
  font-family: var(--font-family);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ant-modal-content ::-webkit-scrollbar,
.ant-modal-wrap ::-webkit-scrollbar {
  width: 15px !important;
  opacity: 0.3;
}

.ant-modal-content ::-webkit-scrollbar :hover,
.ant-modal-content ::-webkit-scrollbar :active,
.ant-modal-content ::-webkit-scrollbar :focus,
.ant-modal-wrap ::-webkit-scrollbar :focus,
.ant-modal-wrap ::-webkit-scrollbar :hover,
.ant-modal-wrap ::-webkit-scrollbar :active {
  /* background: #888 !important; */
}

/* Track */
.ant-modal-content::-webkit-scrollbar-track,
.ant-modal-wrap::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

/* Handle */
/* .ant-modal-content::-webkit-scrollbar-thumb,
.ant-modal-wrap::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* .ant-modal-content::-webkit-scrollbar-thumb:hover,
.ant-modal-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.ant-collapse-borderless,
.ant-collapse {
  background: var(--white) !important;
}

/* custom styles for table header */
.ant-table-thead .ant-table-cell {
  font-weight: bold;
  background-color: #ffffff;
}

/* make all even table rows have a distinct background */
.ant-table-tbody > tr.ant-table-row:nth-child(even) {
  background-color: #f5f7ff;
}

/* The hover state should match the background color set above */
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f5f7ff;
}

/* Make table scroll horizontal when on mobile */
.ant-table {
  overflow-x: auto;
}

.ant-result-title {
  font-size: 23px !important;
  font-weight: bold !important;
}

.ant-result-subtitle {
  font-size: 16px !important;
}

.fac-breadcrumb .ant-row > div {
  margin-left: 0px;
  padding-left: 32px;
}

.profile-section .ant-form-item-control-input,
.password-section .ant-form-item-control-input {
  margin-bottom: var(--input-mb);
}

.password-section .ant-form-item-has-error {
  margin-bottom: 24px;
}

/* .password-section .ant-form-item-has-error .ant-input,
.password-section .ant-form-item-has-error .ant-input-affix-wrapper,
.password-section .ant-form-item-has-error .ant-input:hover,
.password-section .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background: transparent !important;
} */

.filter-popover .ant-popover-content {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  height: unset !important;
  max-width: unset !important;
  color: var(--dark);
  border-radius: 5px;
  overflow-y: unset !important;
}

.filter-popover .ant-popover-inner {
  border-radius: 5px;
}
.filter-popover .ant-popover-title {
  font-family: var(--font-family-bold);
  font-size: 23px;
  padding-left: 48px;
}

.filtering-search {
  border-radius: 5px;
  width: 30rem;
}

.filtering-search > .ant-row:first-of-type {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.filtering-search > .ant-row:first-of-type {
  padding: 0px 32px;
}
.filtering-search > .ant-row:last-of-type {
  margin-bottom: 1rem;
}

.filtering-search .ant-form-item-label {
  max-width: 100%;
}

.filtering-search > .ant-row:first-of-type .ant-col {
  max-width: unset;
}
.filtering-search > .ant-row:first-of-type .ant-col:nth-of-type(odd) {
  margin-right: 1rem;
  /* margin-right: 20px; */
}

/* .filtering-search .ant-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 30px !important;
} */

.filter-icon {
  margin-left: 10px;
}

.filter-icon svg {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.filter-icon svg:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.filter-active svg {
  color: var(--primary);
}

.clear-filter {
  text-align: right;
  color: var(--primary);
  padding-right: 32px;
  cursor: pointer;
}

.clear-filter:hover {
  opacity: 0.8;
}

.schedule__heading .ant-col.text-left {
  max-width: 100%;
  -webkit-flex-basis: unset;
          flex-basis: unset;
}

.lga__lookup__form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}

.comparison-table .ant-table-body {
  overflow: auto !important;
}

td a.ant-dropdown-link svg {
  font-family: var(--font-family-bold) !important;
  font-size: 16px;
  fill: black;
}
td a.ant-dropdown-link {
  /* padding-top: 5px !important; */
  /* padding-bottom: 5px !important; */
  display: block;
  padding: 5px;
  position: relative;
  z-index: 100;
}

.clickable-breadcrumb {
  color: #0f62fe !important;
  /* padding-right: 5px;
  font-size: 14px; */
}

.measurement__container .padded-data-table thead.ant-table-thead tr th.ant-table-cell {
  background: var(--regular-table-header-bg);
}

.dashboard-content {
  display: grid;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  /* row-gap: 1.5rem; */
  grid-auto-columns: 1fr;
  grid-template-columns: minmax(500px, 1fr) minmax(600px, 1.1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
}
@media (max-width: 1469px) {
  .dashboard-content {
    grid-template-columns: minmax(400px, 1fr) minmax(500px, 1.1fr);
  }
}

.dashbaord-dropdown-menu {
  max-height: 25rem;
  width: 10rem;
  overflow-y: scroll;
}

.dashboard-map__component {
  /* grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 2; */
  grid-row: auto / span 2;
}
.dashboard-content .dashboard-content-box {
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.04);
}

@media (max-width: 1300px) {
  .dashboard-content {
    grid-template-columns: minmax(400px, 1fr) minmax(500px, 1.1fr);
  }
}
@media (max-width: 1200px) {
  .dashboard-content {
    grid-template-columns: minmax(500px, 1fr);
  }
  .dashboard-map__component {
    grid-row: auto / span 1;
  }
}

.dashboard-summary,
.dashboard-content {
  padding: 24px 30px;
  font-family: var(--font-family);
}

.dashboard-content {
  padding-top: 0px;
}

.dashboard__summary-col.ant-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-flow: column;
          flex-flow: column;
  width: 15rem;
  max-width: 15rem;
  height: 120px;
  /* width: 200px;
  max-width: 200px;
  height: 120px; */
  box-shadow: 3px 5px 3px -4px rgba(67, 90, 111, 0.2);
  /* box-shadow: -4px -2px 6px -2px rgba(67, 90, 111, 0.5); */
  /* box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7); */
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #f9f4f9;
  overflow: hidden;
  -webkit-flex: unset;
          flex: unset;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dashboard__summary-col.ant-col .dashboard-summary-box {
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 5px;
}

.dashboard__summary-col.ant-col .dashboard-summary-box.box-item-stat {
  font-family: var(--font-family-bold);
  font-size: 23px;
}
.dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label {
  margin-top: 10px;
  padding-top: 0px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--dark);
  opacity: 0.6;
}

.dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label .box-item-view {
  position: absolute;
  right: 8px;
  bottom: 2px;
  opacity: 0.4;
  cursor: pointer;
}
.dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label .box-item-view:hover {
  opacity: 1;
}

.dashboard-content-box {
  margin-bottom: 30px;
}

@media (max-width: 1469px) {
  .dashboard__summary-col.ant-col {
    width: 12rem;
    max-width: 12rem;
  }
}

@media (max-width: 1269px) {
  .dashboard__summary-col.ant-col {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    width: 11rem;
    max-width: 11rem;
    height: 6.5rem;
  }
  .dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label {
    font-size: 12px;
  }
}

@media (max-width: 1150px) {
  .dashboard__summary-col.ant-col {
    width: 9rem;
    max-width: 9rem;
    height: 6rem;
  }
}

@media (max-width: 960) {
  .dashboard__summary-col.ant-col {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    width: 8rem;
    max-width: 8rem;
    height: 6rem;
    margin-bottom: 10px;
  }
  .dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label {
    font-size: 10px;
  }
}

.dashboard-content-box rect {
  fill: steelblue;
}

.dashboard-content-box circle {
  fill: steelblue;
}

.dashboard-content-box .tick text {
  fill: #635f5d;
}

.dashboard-content-box .tick line {
  stroke: #c0c0bb;
}

.dashboard-content-box .axis-label {
  font-size: 12px;
  fill: #8e8883;
}

.dashboard-content-box .title {
  font-size: 1em;
  fill: #636363;
  text-anchor: middle;
}

.dashboard-content-box .line-path {
  fill: none;
  stroke: steelblue;
  stroke-width: 2;
  stroke-linejoin: round;
}

.dashboard-content-box text {
  /* text-anchor: middle; */
  font-size: 12px;
  font-family: var(--font-family);
}

.dashboard-content-box .sphere {
  fill: #4242e4;
}

.filter-popover.filter-pollution-violations .ant-popover-content {
  width: 150px !important;
  overflow-x: hidden;
}
.filter-popover.dashboard-map-filter .ant-popover-content {
  width: 150px !important;
  overflow-x: hidden;
}

.divide-under {
  border-color: rgba(0, 0, 0, 0.0005);
  border-bottom: 1px solid;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.limit-violations-canvas .tick-xscale text {
  font-size: 9px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  /* width: 2px;
  text-overflow: clip; */
}

.violations-trend-canvas .y-axis line {
  opacity: 0.2;
}
.violations-trend-canvas .y-axis path {
  display: none;
}
.violations-trend-canvas .line {
  fill: none;
  stroke-width: 1.5px;
  opacity: 0.75;
}
.violations-trend-canvas .overlay {
  fill: none;
  pointer-events: all;
}
.violations-trend-canvas .lineHoverText {
  text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;
}
.violations-trend-canvas .hoverCircle {
  opacity: 0.75;
}

.dashboard-violations__trend .violation-trend-controls {
  width: 9rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-family: var(--font-family);
  height: 20px;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.violation-trend-controls #selectbox {
  width: 8em;
  font-size: 10px;
  font-family: var(--font-family);
  margin-right: 5px;
  cursor: pointer;
}

.dashboard-map-controls #selectbox {
  width: 20em;
  font-size: 16px;
  font-family: var(--font-family);
  margin-right: 5px;
  cursor: pointer;
  border: none;
}

.dashboard-content-box .filter-popover .ant-dropdown-link {
  font-size: 16px !important;
  font-family: var(--font-family) !important;
}

.limit-violators-canvas .tick text {
  font-size: 9px;
  font-weight: normal;
  font-family: var(--font-family);
  /* font-size: 7.5px; */
}

.SideNav {
  overflow: unset !important;
}
.main-panel {
  overflow: unset !important;
}
body {
  overflow: unset !important;
}
body
  > div:nth-child(10)
  > div
  > div.ant-modal-wrap
  > div
  > div.ant-modal-content
  > div
  > div
  > div.ant-row.ant-row-start.ant-row-middle
  > div
  > div:nth-child(2)
  > div:nth-child(2) {
  overflow-x: hidden !important;
}

body > div:nth-child(13) > div > div.ant-modal-wrap > div > div.ant-modal-content {
  overflow: hidden !important;
}
.ant-modal-content {
  overflow-x: unset;
  overflow: hidden;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/* .facility-overflow__menu {
  background-color: '#f4f4f4';
}

.facility-overflow__menu:hover {
  background-color: '#ffffff';
  color: black;
}

.facility-overflow__menu li:hover {
  -webkit-box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px 2px rgba(0, 0, 0, 0.05);
  color: rgb(4, 46, 46);
  background-color: '#ffffff';
} */

.facility-personnel__others {
  /* style the p */
}

.facility-personnel__notaval {
  /* style the not availae personnel */
}

.reports-table thead,
.reports-table tr,
.reports-table th,
.reports-table .ant-table-cell {
  background: white;
  /* padding: 10px 10px; */
}

.ant-table-tbody tr:nth-child(even) {
  background-color: rgba(225, 234, 243, 0.22);
}

.facility-table__popover {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.facility-table__popover .sample-point__popover {
}
.facility-table__popover .address__popover {
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  /* background-color: rgba(225, 234, 243, 0.22); */
  background: unset;
}

.ant-modal-content {
  /* position: sticky; */
  position: fixed;
  /* top: 25vh; */
  /* left: 15vw; */
  /* right: 15vw; */
  width: 32vw;
  height: 56vh;
  /* bottom: 15vh; */
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.facility-header__main .ant-table {
  font-size: 12px !important;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/* .facility-upload__rows:nth-child(even) {
  background-color: rgba(225, 234, 243, 0.22);
} */

.facility-upload__rows .ant-table-cell-fix-left,
.facility-upload__rows .ant-table-cell-fix-right {
  /* background-color: rgba(225, 234, 243, 0.22); */
  /* background: unset; */
}

.facility-upload__rows td.ant-table-cell {
  /* padding: 8px 16px; */
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.entry-date {
  font-family: var(--font-family);
  /* font-size: x-small !important; */
  /* font-size: x-small; */
  /* font-size: smaller; */
  font-size: 14px;
  /* line-height: 1.5715; */
  font-weight: 100;
  word-break: break-all;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.facility-upload__rows .entry-date {
  font-size: 14px;
}

.recording-form__heading .ant-table-thead th,
.reporting-form__heading .ant-table-thead th {
  font-size: x-small;
  /* padding: 6px 16px; */
}

.recording-form__heading .ant-table-wrapper,
.reporting-form__heading .ant-table-wrapper {
  border-left: 1px solid #f0f0f0;
}
.recording-form__heading .ant-table-header,
.reporting-form__heading .ant-table-header {
  border-top: 1px solid #f0f0f0;
}

.reporting-form__heading thead,
.recording-form__heading thead,
.reporting-form__heading tr,
.recording-form__heading tr,
.reporting-form__heading th,
.recording-form__heading th,
.recording-form__heading .ant-table-cell,
.reporting-form__heading .ant-table-cell {
  background: white;
  padding: 10px 10px;
}

.recording-form__heading th.ant-table-cell,
.reporting-form__heading th.ant-table-cell {
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.recording-form__heading tbody.ant-table-body,
.reporting-form__heading tbody.ant-table-body {
  text-align: center;
  font-size: 11px;
}

.reporting-form__heading .ant-table-row,
.recording-form__heading .ant-table-row,
.recording-form__heading .entry-date,
.reporting-form__heading .entry-date {
  /* font-size: 11px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.reporting-form__heading .report-average,
.recording-form__heading .report-average,
.recording-form__heading .report-average > td,
.reporting-form__heading .report-average > td {
  /* text-align: center; */
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.reporting-form__heading .report-average-2 {
  /* table-layout: fixed; */
  /* padding: 10px 10px; */
  /* display: table-header-group; */
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/* .facility-upload__rows:nth-child(even) {
  background-color: rgba(225, 234, 243, 0.22);
} */

.facility-upload__rows .ant-table-cell-fix-left,
.facility-upload__rows .ant-table-cell-fix-right {
  /* background-color: rgba(225, 234, 243, 0.22); */
  /* background: unset; */
}

.compare-accordion td.ant-table-cell {
  padding: 12px 16px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

  text-align: center;
  /* margin: 0; */
}
.compare-accordion td:last-child.ant-table-cell {
  -webkit-justify-content: center;
          justify-content: center;
}

.entry-date {
  font-family: var(--font-family);
  /* font-size: x-small !important; */
  font-size: x-small;
  font-size: smaller;
  line-height: 1.5715;
  font-weight: 100;
  word-break: break-all;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.reporting-form__heading .ant-table-thead th {
  font-size: x-small;
  /* padding: 6px 16px; */
}

.reporting-form__heading thead,
.reporting-form__heading tr,
.reporting-form__heading th,
.reporting-form__heading .ant-table-cell {
  background: white;
  padding: 10px 10px;
}

.reporting-form__heading th.ant-table-cell {
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.reporting-form__heading tbody.ant-table-body {
  text-align: center;
  font-size: 11px;
}

.reporting-form__heading .ant-table-row,
.reporting-form__heading .entry-date {
  /* font-size: 11px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.reporting-form__heading .report-average,
.reporting-form__heading .report-average > td {
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.reporting.comparing .ant-modal-content .ant-row-start {
  overflow: unset !important;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.viewing .nocursor {
  cursor: pointer;
}

.facility-report__standard.viewing {
  /* justify-content: center; */
}

.viewing .ant-carousel {
  /* this affects justify content center */
  width: 100%;
}

.onviewing-modal-body,
.oncompare-modal-body {
  margin-left: 55px;
  /* margin-right: 79px; */
}

.onviewing-modal-body .ant-carousel {
  margin-bottom: 10px; /* 24px */
}

.viewing .ant-carousel .ant-row {
  -webkit-justify-content: start;
          justify-content: start;
}

.viewing-carousel .facility-card__body.ant-card {
  width: 180px; /* 172px; */
  height: 150px; /* 140px; */
  border-radius: 3.5px;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  margin-bottom: 69px;
}

@media screen and (max-width: 1400px) {
  .viewing-carousel .facility-card__body.ant-card {
    width: 172px !important; /* 172px; */
  }
  .viewing-carousel .facility-card__body.ant-card .qtr-text {
    margin-bottom: unset !important;
  }
}

.viewing .facility-report__body .ant-card {
  /* width: auto !important; */
}
.viewing .facility-report__body .ant-card .qtr-subtext {
  text-overflow: ellipsis;
  font-family: var(--font-family);
  font-size: 11.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.viewing .facility-report__body .ant-card .qtr-text {
  text-overflow: ellipsis;
  font-family: var(--font-family);
  font-size: 11.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: unset;
}
.facility-report__standard {
  /* margin- */
}

.reporting.viewing .ant-modal-content {
  /* width: 90vw !important;
  height: 90vh;
  transform: translate(-16rem, -3rem); */
  width: 923px !important;
  height: 750px !important;
  /* -webkit-transform: translate(-15rem, -4rem);
  -ms-transform: translate(-15rem, -4rem);
  transform: translate(-15rem, -4rem); */
  -webkit-transform: translate(-12.5rem, -1.5rem);
          transform: translate(-12.5rem, -1.5rem);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

@media screen and (max-width: 1269px) {
  .reporting.viewing .ant-modal-content {
    -webkit-transform: translate(-12rem, -5.5rem);
            transform: translate(-12rem, -5.5rem);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    /* transform: translate(-12rem, -3rem); */
    /* transform: translate(-5.5rem, -5.5rem); */
  }
}

.reporting.viewing .ant-modal-body {
  height: 100%;
  max-height: 100%;
}

/* .facility-report__body .onviewing-modal {
  height: 100% !important;
} */

.onviewing-modal .ant-row-start {
  margin-bottom: 1.6rem;
}

.reporting ul.ant-table-pagination.ant-pagination {
  display: none;
}

.onviewing-modal .facility-reporting__form .ant-form-item {
  width: 100% !important;
  /* margin-bottom: 1rem !important; */
}

.viewing .ant-modal-body {
  background-color: #ffffff;
}

.viewing .ant-modal-content {
  /* height: 44.9rem; */
  /* height: 40rem; */
  /* width: 44.08rem !important; */
  /* width: 65.1rem; */
  /* -webkit-transform: translate(-8rem, 0);
  -ms-transform: translate(-8rem, 0);
  transform: translate(-8rem, 0);
  border-radius: 5px !important; */
}

.onviewing-modal .arrow-back::before {
  content: '\2190';
  z-index: 100;
  width: 200px;
  -webkit-transform: translateY(-1rem) !important;
  transform: translateY(-1rem) !important;
  position: absolute;
  cursor: pointer;
}

.spin-fullpage {
  height: 100vh;
  z-index: 100;
}

.spin-fullpage span {
  width: 100px;
}

.onreporting-modal .ant-carousel .slick-dots-bottom {
  bottom: -2rem;
}

.viewing-carousel ul.slick-dots-bottom {
  /* bottom: -0.7rem; */
  -webkit-transform: translate(-12px, 1.6rem);
  transform: translate(-12px, 1.6rem);
}

.onreporting-modal .ant-carousel .slick-dots li,
.ant-carousel .viewing-carousel .slick-dots li {
  height: 0.4rem;
  width: 2.4em;
  border: 1px solid lightskyblue;
}

.ant-carousel .viewing-carousel .slick-dots li {
  height: 0.4rem;
  width: 2.4em;
  /* border: 1px solid lightskyblue; */
  border: none;
  background-color: rgba(79, 85, 85, 0.4);
}

.ant-carousel .viewing-carousel .slick-dots li.slick-active {
  background-color: darkslategrey;
  background: darkslategrey;
}

.onreporting-modal .ant-carousel .slick-dots li button,
.ant-carousel .viewing-carousel .slick-dots li button {
  height: 100%;
  border: none;
  border: 1px solid darkslategrey;
  background-color: darkslategrey;
}

.reporting .ant-result-icon {
  margin-top: 15%;
}

.ant-result-icon svg {
  width: 104.8px;
  height: 104.8px;
  object-fit: contain;
}

.reporting .ant-modal-body .anticon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.reporting .ant-result-warning .ant-result-icon > .anticon {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.reporting.comparing .ant-modal-content {
  /* width: 65.5rem !important;
  height: 40rem; */
  /* -webkit-transform: translate(-15rem, -4rem);
  -ms-transform: translate(-15rem, -4rem);
  transform: translate(-15rem, -4rem);
  */

  width: 1050px !important;
  height: 750px;
}

@media screen and (max-width: 1269px) {
  .reporting.comparing .ant-modal-content {
    -webkit-transform: translate(-15rem, -5.5rem);
            transform: translate(-15rem, -5.5rem);
  }
}

.reporting.comparing .ant-modal-body {
  height: 100%;
  max-height: unset;
}

.comparing .facility-report__body.onviewing-modal {
  height: 100%;
}

.facility-report__standard .ant-modal-body {
  /* height: 18rem; */
  /* height: 100%; */
}

.comparing .onviewing-modal {
  height: 100%;
}
.comparing .onviewing-modal .ant-row-start {
  -webkit-align-content: space-between;
          align-content: space-between;
  margin-bottom: 0.8rem;
}

.recording .facility-reporting__form {
  margin-top: 1.6rem;
}

.comparing ul.ant-table-pagination.ant-pagination {
  display: none;
}

.compare-accordion {
  /* width: 58rem; 
  height: 48rem;  */
  width: 928px;
  /* height: 600px; */
  /* height: 400px; */
  /* height: 22rem; */
  height: 28rem;
  overflow: scroll;
  background: inherit;
  overflow: scroll;
}

.comparing .ant-collapse-content-box {
  /* overflow-x: scroll; */
}
.comparing .ant-collapse-content-box {
  /* overflow-x: scroll; */
}

.comparing .comparison-table .ant-table-wrapper {
  overflow-x: scroll;
}

.comparing .switch-average-type {
  -webkit-align-self: center;
          align-self: center;
  max-width: unset;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.compare-accordion .ant-collapse-header[aria-expanded='false'] {
  padding: 24px 0;
  /* font-weight: bold; */
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
  padding-left: 0px !important;
}

.compare-accordion .ant-collapse-header[aria-expanded='true'] {
  /* font-size: 12.8px; */
  padding: 12px 0 !important;
  font-weight: bold;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.compare-accordion .ant-collapse-header span {
  /* letter-spacing: 1.6px; */
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}
.compare-accordion .ant-collapse-header span > p {
  /* letter-spacing: 1px; */
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.compare-accordion .ant-collapse-content-box > div:first-child {
  padding: 0 !important;
  /* max-width: 95%; */
}

.comparing .compare-accordion .ant-collapse-content-active {
  position: relative;
}

.compare-accordion ul.ant-table-pagination.ant-pagination {
  display: none;
}

.compare-accordion td.ant-table-cell {
  padding: 12px 16px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}

.compare-accordion thead,
.compare-accordion th,
.compare-accordion th .ant-table-cell {
  color: var(--compare-table-head-color);
  background: var(--compare-table-head-bg);
}

.published.reporting-form__heading thead {
  /* .published.reporting-form__heading th { */
  /* .published.reporting-form__heading .ant-table-cell { */
  color: var(--compare-table-head-color) !important;
  background: var(--compare-table-head-bg) !important;
}

.published.reporting-form__heading th.ant-table-cell {
  font-size: 9.6px;
  font-family: var(--font-family);
  color: var(--compare-table-head-color);
  background: var(--compare-table-head-bg);

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  text-align: center;
}

.published.reporting-form__heading td.ant-table-cell {
  /* padding: 12px 16px; */
  font-family: var(--font-family) !important;
  font-size: 12px;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  background: #ffffff;
  background-color: #ffffff;
  text-align: center;
}

.published.reporting-form__heading .ant-table-row {
  /* font-size: 8.6px; */
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #9b9b9b; */
  color: #ffffff !important;
  font-family: var(--font-family) !important;
  background-color: var(--non-qaqc-highlight);
  font-weight: normal !important;

  /* background: #c0c3cc; */
  border: none;
}

.published.reporting-form__heading tfoot.ant-table-summary tr {
  /* background-color: #2e5bff; */
  padding: 0;
  font-size: 12px;
  background-color: var(--qaqc-highlight);
  font-family: var(--font-family) !important;
  color: var(--dark);
}

.published.reporting-form__heading tfoot.ant-table-summary tr td {
  background-color: var(--qaqc-highlight) !important;
  background: var(--qaqc-highlight) !important;
  font-family: var(--font-family) !important;
  padding: unset !important;
  font-size: 12px;
}

.published.reporting-form__heading tfoot > tr > th,
.published.reporting-form__heading tfoot > tr > td {
  padding: 12px !important;
}

.published.reporting-form__heading tfoot.ant-table-summary tr > th {
  background: var(--qaqc-highlight) !important;
}

.compare-accordion th.ant-table-cell {
  /* font-size: 9.6px; */
  font-family: var(--font-family);
  color: var(--compare-table-head-color);
  background: var(--compare-table-head-bg);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  text-align: center;
}

.compare-accordion tbody.ant-table-body {
  /* text-align: center;
  font-size: 8.8px; */
}

.compare-accordion .ant-table-row {
  /* font-size: 8.6px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #9b9b9b; */
  color: #ffffff;
  background-color: var(--non-qaqc-highlight);
  /* background: #c0c3cc; */
  border: none;
}

.compare-accordion .ant-table-row[data-row-key='0-0'] {
  /* background-color: #2e5bff; */
  background-color: var(--non-qaqc-highlight);
  color: var(--dark);
}
/* .compare-accordion .ant-table-row td:last-child {
  background-color: #ffffff !important;
  background: #ffffff !important;
  color: #ffa97f;
  border: none;
  box-shadow: none;
} */

.compare-accordion .ant-table-row td.ant-table-cell {
  color: var(--compare-table-cell-color);
  background-color: var(--non-qaqc-highlight);
  background: var(--non-qaqc-highlight);
  /* background-color: #585a5f; */
  /* background: #585a5f; */
  /* background: #c0c3cc; */

  /* background-color: rgb(88, 90, 95, 0.7); */
  border: none;
}

.compare-accordion .ant-table-row:not([data-row-key='0-0']):hover > td {
  /* color: #ffffff; */

  background-color: var(--non-qaqc-highlight);
  background: var(--non-qaqc-highlight);
  border: none;
}

.compare-accordion .ant-table-row[data-row-key='0-0'] td.ant-table-cell {
  /* background-color: rgba(84, 100, 160, 0.6) !important; */
  background-color: var(--qaqc-highlight) !important;
  background: var(--qaqc-highlight) !important;
  /* background-color: rgba(85, 108, 192, 1) !important; */
  /* background-color: rgba(63, 86, 170, 0.9) !important; */
  /* background-color: #2e4083 !important; */
  /* background: rgba(46, 91, 255, 0.7) !important; */
  /* background: #2e4083 !important; */
}

/* .compare-accordion .ant-table-row td:last-child.ant-table-cell {
  background-color: #ffffff !important;
  background: #ffffff !important;
  display: flex;
  color: #2c2d2e;
  border: none;
  box-shadow: none;
} */

/* .compare-accordion .ant-table-row td:last-child.ant-table-cell > .compare-avg:after {
  content: '';
  font-size: 8px !important;
  padding: 0.0005rem 0.36rem;
  vertical-align: middle;
  margin-right: 5px;
  
  color: #ffa97f;
  background-color: #ffa97f;
} */

.compare-accordion .ant-table-row td:last-child.ant-table-cell > span:first {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  text-align: left;
  border: none;
  width: 100%;
  margin-right: 1.6px;
  overflow: hidden;
  padding-right: 4px;
  box-shadow: none;
}
.compare-accordion .ant-table-row td:last-child .compare-avg {
  width: 24px;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-right: 2.4px;
}

.compare-accordion .ant-table-row td:last-child .compare-avg-above,
.compare-accordion .ant-table-row td:last-child .compare-avg-below {
  content: '';
  font-size: 6.4px !important;
  padding: 0.0004rem 0.288rem;
  vertical-align: middle;
  /* color: #ac000d;
  background-color: #ac000d; */
  text-align: right;
  place-content: flex-end;
  -webkit-justify-content: end;
          justify-content: end;
  -webkit-align-self: end;
          align-self: end;
}

.compare-accordion .ant-table-row td:last-child.ant-table-cell .compare-avg-above {
  /* color: #00bf00;
  background-color: #00bf00; */
  text-align: right;
}
.compare-accordion .ant-table-row td:last-child.ant-table-cell .compare-avg-below {
  /* color: #ac000d;
  background-color: #ac000d; */
  text-align: right;
}

.compare-accordion .qaqc-underneath:before,
.compare-accordion .cons-underneath:before {
  /* .compare-accordion .average-underneath-a:before, */
  /* .compare-accordion .average-underneath-b:before { */
  content: '';
  font-size: 6.4px !important;
  color: var(--qaqc-highlight);
  background-color: var(--qaqc-highlight);
  padding: 0.0004rem 0.288rem;
  vertical-align: middle;
  margin-right: 4px;
}

.compare-accordion .cons-underneath:before {
  background-color: rgba(151, 156, 168, 0.7);
}

.compare-accordion .average-underneath-a:before {
  /* background-color: #00bf00; */
}
.compare-accordion .average-underneath-b:before {
  /* background-color: #ac000d; */
}

.compare-accordion .switch-average-type {
  /* height: 7.2rem; */
}

.compare-accordion .switch-average-type p {
  font-weight: bold;
  /* letter-spacing: 2px; */
}

.compare-accordion .switch-average-type .ant-radio-group {
  /* margin-top: 0.8rem; */
}

.compare-accordion .switch-average-type .ant-radio-group .ant-radio-wrapper {
  margin-right: unset;
  /* margin-bottom: 0.8rem; */
}

.compare-accordion .switch-average-type .ant-radio-group.ant-radio-group-outline {
  margin-left: 1rem;
}

.compare-accordion .switch-average-type .ant-radio-group .ant-radio-wrapper span.ant-radio + * {
  padding-left: 14.4px;
}

.compare-accordion .qaqc-underneath,
.compare-accordion .average-underneath-a,
.compare-accordion .average-underneath-b,
.compare-accordion .cons-underneath {
  /* font-size: 9.6px; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(54, 91, 160, 0.5);
  margin-right: 0.8rem;
  vertical-align: middle;
  font-family: var(--font-family) !important;
}
.compare-accordion .cons-underneath {
  color: var(--non-qaqc-highlight);
}

.compare-accordion .average-underneath-a {
  color: #00bf00;
  margin-right: 0;
}
.compare-accordion .average-underneath-b {
  color: #ac000d;
}

.compare-logo-col {
  overflow: visible !important;
}

.compare-logo {
  height: 120px;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  margin-top: -60px;
  /* margin-left: -60px; */
  font-size: 28.8px;
}
.compare-logo .sq {
  height: 80px;
  width: 80px;
  z-index: 1;
  border: 1px solid #ccc;
  background-color: #ffffff;
  position: absolute;
}

.compare-logo .sq.sq-letter p:before {
  content: '\B7\B7\B7\B7\B7   \2605   \B7\B7\B7\B7\B7';
  vertical-align: middle;
  text-align: center;
  width: 100%;
  /* margin-bottom: 5px; */
  padding: 1.6px 4px;
  display: block;
  font-family: 'LucidaGrande';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #fffffe;
}
.compare-logo .sq.sq-letter p:after {
  content: '\23BB\23BB\23BB\23BB';
  vertical-align: middle;
  text-align: center;
  width: 100%;
  margin-bottom: 4px;
  padding: 4px;
  font-weight: bolder;
  display: block;
  font-family: 'LucidaGrande';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.6px;
  word-break: break-all;
  color: #fffffe;
}

.compare-logo .sq.sq-letter {
  /* line-height: 100px; */
  font-size: 19.2px;
  height: 96px;
  width: 96px;
  border-radius: 50%;
  -webkit-transform: translate(-8%, -8%);
  transform: translate(-8%, -8%);
  padding: 8px;
  z-index: 100;
  text-align: center;
  border: none;
}

.compare-logo .sq.sq-letter:not(.published) {
  background-color: rgba(221, 66, 75, 1);
}

/* .compare-logo .sq.sq-letter.draft {
  background-color: rgba(221, 66, 75, 1);
} */

.compare-logo .sq.sq-letter.published {
  background-color: rgb(8, 155, 160);
}

.compare-logo .sq.sq-letter p {
  font-family: 'LucidaGrande';
  font-size: 12px;
  /* font-size: 9.6px; */
  margin: 0 auto !important;
  font-weight: normal;
  padding: 4px;
  letter-spacing: 1.6px;
  color: #fffffe;
}

.compare-logo .sq-one {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background: transparent;
}
.compare-logo .sq-one-2 {
  -webkit-transform: rotate(5deg);
  transform: rotate(5deg);
}
.compare-logo .sq-two {
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
}
.compare-logo .sq-two-2 {
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}
.compare-logo .sq-three {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}
.compare-logo .sq-three-2 {
  -webkit-transform: rotate(25deg);
  transform: rotate(25deg);
}
.compare-logo .sq-four {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.compare-logo .sq-four-2 {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}
.compare-logo .sq-five {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
.compare-logo .sq-five-2 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.compare-logo .sq-six {
  -webkit-transform: rotate(50deg);
  transform: rotate(50deg);
}
.compare-logo .sq-six-2 {
  -webkit-transform: rotate(55deg);
  transform: rotate(55deg);
}
.compare-logo .sq-seven {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.compare-logo .sq-seven-2 {
  -webkit-transform: rotate(65deg);
  transform: rotate(65deg);
}
.compare-logo .sq-eight {
  -webkit-transform: rotate(70deg);
  transform: rotate(70deg);
}
.compare-logo .sq-eight-2 {
  -webkit-transform: rotate(75deg);
  transform: rotate(75deg);
}
.compare-logo .sq-nine {
  -webkit-transform: rotate(80deg);
  transform: rotate(80deg);
}
.compare-logo .sq-nine-2 {
  -webkit-transform: rotate(85deg);
  transform: rotate(85deg);
}
.compare-logo .sq-ten {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.compare-logo .sq-ten-2 {
  -webkit-transform: rotate(95deg);
  transform: rotate(95deg);
}

.ant-popover {
  padding: 20px 16px;
}
.ant-popover > .ant-popover-content {
  width: 250px;
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: -2px 2px 20px -7px rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);
}
.box {
  margin-bottom: 9px;
  font-size: 14px;
}
.box.box-hidden {
  overflow-x: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.box > .dark {
  color: #000;
  text-transform: capitalize;
  width: 50px;
  margin-right: 15px;
}
.box > .light {
  color: #9b9b9b;
  font-family: var(--font-family);
  /* float: right; */
  /* margin-left: 15px; */
  word-break: break-all;
  white-space: nowrap;
  text-overflow: clip;
}
.role-badge {
  float: right;
  color: #007ace;
  background: #e7f5ff;
  padding: 5px 6px;
  font-family: var(--font-family);
  border-radius: 10px;
  font-size: 12px;
}
.regulator__input.ant-select {
  width: 100%;
}

.user__header__main {
  /* padding: 24px 30px; */
  max-width: 100%;
  /* margin-bottom: 14px; */
}

.user__header__main .ant-row-end {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.user__header__main .ant-select-selector {
  height: 32px !important;
  border-radius: 5px;
  font-family: var(--font-family);
}
.user__header__main .ant-select-selector .ant-select-selection-placeholder {
  line-height: 30px;
  font-size: 14px;
  font-family: var(--font-family);
}
.user__header__main .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 32px;
}
.users__tab .ant-tabs-nav {
  font-family: var(--font-family);
  font-size: 14px;
  color: #2e384d;
}

.ant-select-item-option-content {
  font-family: var(--font-family);
}

.empty__users {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 3rem;
  font-family: var(--font-family);
}
.empty__users .users__icon {
  width: 200px;
  height: 200px;
  opacity: 0.2;
}
.empty__users .content {
  width: 100%;
  text-align: center;
}
.empty__users .content h3 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 2rem !important;
}
.empty__users .content p {
  color: #828ea9;
  font-size: 16px;
  margin-top: 0.5rem !important;
}

.users__tab {
  margin-top: 2rem;
}
.users__tab .ant-tabs-tab-active.ant-tabs-tab {
  color: #0c5bab;
}
.users__tab .ant-tabs-ink-bar {
  background-color: #0c5bab;
}
.update__action {
  display: block;
  z-index: 100;
  position: relative;
  padding: 5px;
}
.users__loader {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.create-user-btn button {
  height: 32px;
  font-family: var(--font-family);
  padding: 0 1rem;
}
.user-overflow__menu {
  font-family: var(--font-family);
}
.user-table__rows {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}
.create-user-btn button {
  height: 32px;
  margin-left: 0;
}
.regulator__input.ant-select {
  width: 100%;
}

.update__user__modal {
  width: 900px !important;
  min-height: 600px !important;
  height: auto;
  font-family: var(--font-family);
  margin-bottom: 3rem;
}
.update__user__modal .ant-modal-content {
  width: inherit;
  height: inherit;
  padding: 3rem 2rem;
  position: -webkit-sticky !important;
  position: sticky !important;
}
.update__user__modal .ant-modal-header {
  border-bottom: none;
}
.update__user__modal .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
}
.update__user__modal .update__user__tabs {
}
.update__user-form .ant-select-selection-item-content {
  font-weight: 600;
}
.update__user-form .form-group {
  margin-bottom: 1.3rem;
}

.update__user-form .form-group label {
  font-weight: 600;
}

.ant-select-item {
  font-size: 14px !important;
}
.update__user-form .ant-select-selection-placeholder {
  font-size: 14px;
}

.update__user-form .ant-checkbox-group-item + .ant-checkbox-group-item {
  display: block;
}
.update__user-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
}
.update__user-form .ant-checkbox-wrapper {
  margin-bottom: 17px;
  display: block;
}
.update__user-form .ant-checkbox-wrapper > span {
  color: #828ea9;
  font-weight: normal;
  text-transform: capitalize;
}
.update__user-form .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.update__user-form .save__btn {
  background: #0c5bab;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 10px;
  padding: 0.5rem 1.5rem;
  margin-top: 2rem;
}
.update__user-form .cancel__btn {
  background: transparent;
  color: #005bab;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1.5rem;
  margin-top: 2rem;
  border: 1px solid;
}
.update__user-form .error-text {
  text-align: center;
  font-size: 16px;
}
.update__user-form .ant-form-item {
  width: 100%;
}
.update__user-form .ant-form-item-explain {
  margin-top: 0;
}

.update__user__modal .update__unavailable {
  text-align: center;
}
.update__unavailable svg {
  opacity: 0.2;
  width: 100px;
  height: 100px;
}
.update__unavailable h3 {
  font-weight: 600;
  margin-top: 1rem !important;
}
.update__unavailable button {
  background: #0c5bab;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 0.7rem 2rem;
  border-radius: 5px;
  margin-top: 1rem;
}
.update__user-form label {
  color: #2e384d;
}
.update__user-form .ant-form-item-label label {
  font-family: var(--font-family);
  font-weight: 600;
  color: #2e384d;
}
.update__user-form .ant-select-multiple .ant-select-selection-item {
  background: #e7f5ff;
  border-radius: 10px;
  border: none;
  font-size: 12px;
  color: #0c5bab;
  padding: 5px 5px 0 10px;
  height: 30px;
}
.area__badge {
  background: #e7f5ff;
  color: #0c5bab;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 10px;
}
.delete__area {
  border: none;
  color: #0c5bab;
  border-radius: 4px;
  background: transparent;
}
.user__area {
  margin-bottom: 1rem;
}
.area__title {
  margin-bottom: 1rem !important;
  font-weight: 600;
}

.profile__heading {
  font-weight: 600;
  color: #2e384d;
}
.profile__text {
  color: #828ea9;
  margin-bottom: 1rem !important;
}
.area__summary__list {
  list-style-type: disc;
}
.area__summary__list li {
  font-weight: 600;
}
.add__btn {
  background: #0c5bab;
  color: #fff;
  font-weight: 600;
  font-weight: 16px;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  border: none;
}
.delete__btn {
  color: #db3236;
  font-weight: 600;
  font-weight: 16px;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  border: none;
  background: transparent;
}
.delete__btn:focus {
  border: 1px solid #db3236;
  outline-color: transparent;
}

.add__more__container {
  /* margin-top: 2rem; */
}
.delete__areas__container {
  /* margin-top: 2rem; */
}
.add__more__container .ant-select-selection-placeholder {
  line-height: 40px !important;
}

.area__container {
  margin-top: 1.5rem;
}
.update__user__modal .close__btn {
  margin-top: 2rem;
  border: none;
  padding: 0.5rem;
  color: #2e384d;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}
.areas__heading {
  font-weight: bold;
}
.ant-tabs.update__user__tabs .ant-tabs-nav .ant-tabs-tab{
  text-align: left;
}
.email-form .ant-input[disabled] {
    background: rgba(130, 142, 169, 0.3);
}

.config__container {
    background: #f9f9f9;
    font-family: var(--font-family);
    padding-top: 1rem;
    min-height: calc(100vh - 70px);
}

.ant-tabs.config__tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left !important;
    font-size: 16px;
    margin-right: 1rem;
}
.ant-tabs.config__tabs .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--dark);
    font-family: var(--font-family);
    background: #f3f7fb;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.ant-tabs.config__tabs .ant-tabs-nav .ant-tabs-tab:hover {
    color: var(--sidebar-bg);
}

.ant-tabs.config__tabs .ant-tabs-ink-bar {
    background-color: var(--sidebar-bg) !important;
    display: none !important;
}

.parameters__container,
.measurement__container {
    min-height: calc(100vh - 70px);
}

.unit__btn {
    background: transparent;
    border: none !important;
    padding: 0;
    height: 20px;
}
.unit__btn img {
    width: 20px;
    /* border: 1px solid; */
    height: 20px;
}
.unit__popover {
    background: #fff;
    width: 500px;
    height: 150px;
}
.unit__popover .ant-popover-content {
    width: inherit;
    height: 150px;
    overflow: hidden;
    box-shadow: none;
}
.unit__popover .ant-popover-inner-content {
    box-shadow: none !important;
}
.unit__popover .ant-popover-inner {
    box-shadow: none;
}
.unit__popover .ant-popover-arrow {
    bottom: -4.8px !important;
}
.transparent__bg {
    background: transparent;
}

.notification__form {
    font-family: var(--font-family);
}

.notification__form .ant-form-item-label label {
    font-weight: 600;
    font-family: var(--font-family-bold);
    font-size: 16px;
}

.config__switch.ant-switch-checked {
    background: var(--sidebar-bg) !important;
}
.config__switch.ant-switch {
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
}

.config__switch .ant-switch::after {
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.config__checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--sidebar-bg);
    border-color: var(--sidebar-bg) !important;
}
.config__units {
    font-size: 16px !important;
}

.config__units .ant-radio-checked .ant-radio-inner {
    border-color: var(--sidebar-bg);
    background-color: var(--sidebar-bg);
}
.config__units .ant-radio-inner::after {
    background-color: #fff;
}

.ant-collapse.custom__collapse,
.custom__collapse__panel {
    background: transparent !important;
    border-radius: 2px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
}
.ant-collapse.custom__collapse .ant-collapse-header {
    font-family: var(--font-family-bold);
    text-transform: uppercase;
}

/* MODAL */

.add__measurement__modal {
    background: #fff;
    font-family: var(--font-family);
    height: 300px;
    width: 600px;
    border-radius: 13px;
}
.add__measurement__modal .ant-modal-content {
    border-radius: 13px;
    width: inherit;
    height: inherit;
    background: #fff;
}
.add__measurement__modal .ant-modal-title {
    font-family: var(--font-family-bold);
    font-size: 18px;
}
.add__measurement__modal .ant-form-item-label label,
.add__parameter__modal .ant-form-item-label label {
    font-weight: 600;
}

.add__parameter__modal {
    font-family: var(--font-family);
    border-radius: 13px;
    min-height: 300px;
    width: 800px !important;
}
.add__parameter__modal .ant-modal-content {
    height: inherit;
    width: inherit;
    border-radius: 13px;
}
.add__parameter__modal .parameter__input .ant-input-number {
    width: 100%;
    border-radius: 5px;
}
.add__parameter__modal .ant-modal-title,
.edit__measurement__modal .ant-modal-title,
.edit__parameter__modal .ant-modal-title {
    font-family: var(--font-family-bold);
}
.edit__measurement__modal {
    font-family: var(--font-family);
    border-radius: 13px;
    height: 300px;
}
.edit__measurement__modal .ant-modal-content {
    border-radius: 13px;
    height: inherit;
    width: inherit;
}

.parameter__table td:nth-child(5) {
    text-align: right;
}

.config__switch.ant-switch-disabled {
    opacity: 0.3;
}
.config__switch.config__switch.ant-switch-disabled + .switch__text {
    opacity: 0.3;
}
.add__params__btn {
    -webkit-transform: translateY(-47px);
            transform: translateY(-47px);
}
.measurements__row {
}

/* EDIT PARAMETERS */
.edit__parameter__modal {
    font-family: var(--font-family);
    border-radius: 13px;
    min-height: 300px;
    width: 800px !important;
}
.edit__parameter__modal .ant-modal-content {
    width: inherit;
    height: inherit;
    border-radius: 13px;
}
.edit__parameter__form .ant-form-item-label {
    font-weight: 600;
}
.edit__parameter__form .ant-input-number.parameter__input {
    width: 100%;
    border-radius: 5px;
}
.edit__parameter__form .ant-form-item-explain {
    margin-top: 0;
}
.breadcrumb__container {
    padding-bottom: 2rem;
}
.breadcrumb__container .ant-breadcrumb .ant-breadcrumb-link a {
    color: #0c5bab !important;
}

.ant-table-wrapper.measurement__table .ant-table-thead > tr > th {
    background: #f3f7fb !important;
}

.step-form div,
.step-form label,
.step-form p,
.step-form li,
.step-form strong {
  font-family: var(--font-family);
}

.step-form ul {
  padding: 0;
  list-style: none;
}

.step-form li {
  padding-bottom: 1%;
}

.step-form li:focus {
  outline: none;
  color: #780b1c;
  font-weight: bold;
}

.step-form a {
  text-decoration: none;
}

.step-form .app {
  height: 40vh;
  width: 80%;
  /* height: 100vh;
  width: 100vw; */
}

.step-form #map {
  height: 100%;
}

.step-form .filter-button {
  display: none;
  position: absolute;
  text-align: center;
  font-size: 1em;
  color: #fff;
  margin-left: 3vw;
  margin-top: 3vh;
  padding: 1%;
  border: 1px solid #fff;
  background-color: #731725;
  z-index: 3;
}

.step-form .listings-container {
  display: none;
  position: absolute;
  /* width: 25vw; */
  /* min-width: 180px; */
  /* margin-left: 3vw; */
  /* margin-top: 10vh; */
  width: 15vw;
  min-width: 100px;
  max-height: 50vh;
  overflow-y: scroll;
  margin-left: 1vw;
  margin-top: 5vh;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 1;
  padding: 2%;
}

.step-form .display {
  display: block;
}

.step-form .info-box {
  position: absolute;
  z-index: 2;
  width: 30%;
  min-height: 40%;
  margin-right: 3%;
  margin-top: 3%;
  border: 1px solid black;
}

.step-form .close-box {
  display: block;
  width: 100%;
  border: none;
  background-color: #fff;
  float: right;
  text-decoration: none;
  color: #731725;
  font-size: 1rem;
  cursor: pointer;
  text-align: right;
}

.step-form .info-box-message {
  text-align: justify;
}

.step-form .img-container {
  width: 100%;
  margin: auto;
}

.step-form .pub-img {
  width: 100%;
  border: 1px solid #000;
}

.step-form .overview-box {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #591934;
  border: none;
  padding: 0;
  z-index: 5;
  text-align: center;
  display: table;
  opacity: 0.92;
}

.step-form.overview-title {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  opacity: 1;
  text-align: center;
}

.step-form.display-none {
  display: none;
}

.step-form.filtered-pubs-titles {
  color: #731725;
}

.step-form.hint {
  font-size: 0.7em;
  font-style: italic;
}

.facility-details__layout section#pubfilter {
  max-height: 45vh;
}
.facility-details__layout section#pubfilter .listing-results {
  max-height: 27vh;
  overflow: scroll;
}

@media only screen and (max-width: 600px) {
  .step-form input {
    border: 1px solid #a1a1a1;
  }

  .step-form .info-box {
    width: 50%;
  }

  .step-form .close-info-box {
    background-color: #fff;
  }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

#facilities .small-heading {
  font-family: var(--font-family-bold);
  /* font-size: 18px; */
  /* font-weight: 900; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.steps-content {
  /* margin-top: 16px; */
  /* border: 1px dashed #e9e9e9; */
  border-radius: 2px;
  /* background-color: #fafafa; */
  min-height: 160px;
  text-align: left;
  padding-top: 1.6rem;
}

.step-form {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  overflow-y: unset;
}

.ant-form-item-explain {
  margin-top: -0.72rem;
}

.steps-action {
  margin-top: 19.2px;
  margin-bottom: 0.8rem;
}

h4.px-0 {
  padding-left: 0 !important;
}

.step-form .ant-form-item {
  margin-bottom: 1px;
}

.ant-select-selection,
.steps-content .form-control {
  margin-top: 0.64rem;
  margin-bottom: 1.44rem;
}

.steps-content .form-control {
  margin-top: 0.64rem;
  margin-bottom: 1.44rem;
  color: rgba(0, 0, 0, 0.65);
  font-family: var(--font-family);
}

.form-controlx {
  height: 41px;
  width: 100%;
  margin-bottom: 0.8rem;
  padding: 0.64rem 0.8rem;
  font-family: var(--font-family);
  /* border-radius: 5px;
  border: solid 1px rgba(97, 90, 90, 0.1); */
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(245, 247, 255, 0.2);
  border-radius: 5px;
  border: solid 1px rgba(216, 216, 216, 1);
}

.form-controlx:active,
.form-controlx:focus,
.form-controlx:hover {
  outline: none;
  border-color: #40a9ff;
  border-right-width: 1px;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.step-form input::-webkit-input-placeholder {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  opacity: 0.4;
}
.step-form input::-ms-input-placeholder {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  /* color: rgba(216, 216, 216, 0.4) */
}
.step-form input::placeholder {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  /* color: rgba(216, 216, 216, 0.4) */
}

.form-controlxx {
  /* height: 41px;
  width: 100%; */
  /* margin-bottom: 1rem;
  padding: 0.8rem 1rem; */
  font-family: var(--font-family);
  color: rgba(0, 0, 0, 0.65);
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(245, 247, 255, 0.2);
}

.form-controlxx:active,
.form-controlxx:focus,
.form-controlxx:hover {
  /* outline: none; */
  border-color: #40a9ff;
  border-right-width: 1px;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* .form-controlxx .ant-select-selector span {
  transform: translateY(40px);
  display: inline-flex;
  /* position: relative; */
/* display: block; */
/* max-width: 600px; */
/* border: 1px solid #ddd; */
/* margin-top: 1.5em; */
/* margin-bottom: 1em; */
/* }  */

.step-form .ant-form-item-control {
  max-width: 100%;
}

.step-form .ant-form-item-label {
  max-width: 50%;
}

.step-form .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  content: '';
}
.step-form .ant-form-item-label > label::after {
  content: '';
}

.tagClass {
  --antd-wave-shadow-color: #1890ff;
  text-align: left;
  line-height: 1.5715;
  list-style: none;
  cursor: move;
  padding-top: 2.4px;
  padding-bottom: 2.4px;
  padding-left: 9.6px;
  padding-right: 9.6px;
  border-radius: 4.8px 4.8px 4.8px 4.8px;
  margin-bottom: 0.8rem;
  margin-right: 0.8em;
  /* width: 110px;
  height: 25px; */
  border-radius: 11.6px;
  background-color: #e7f5ff;
  /* font-size: 12px; */
  /* font-weight: normal; */
  font-stretch: normal;
  /* font-style: normal;
  line-height: normal; */
  /* letter-spacing: normal; */
  color: #0c5bab;
}

.selectedClass {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: wrap;
          flex-flow: wrap;
  margin-top: 8px;
  margin-bottom: 0.8rem;
}

.removeClass {
  /* background-color: rgba(0, 0, 0, 0.1);
  padding-right: 2px;
  padding-bottom: 1px;
  border-radius: 80% 85% 75% 90%; */
  color: rgba(0, 0, 0, 0.2) !important;
  padding-left: 2px;
  margin-left: 4px;
  /* color: white; */
  /* border-radius: 50% 50%; */
}

.removeClass:hover {
  color: #d0021b !important;
}

.removeClass:hover {
  color: rgba(221, 42, 96, 0.8);
}

.suggestionsClass .activeSuggestionsClass {
  list-style: none;
}

.tagsClass .suggestionsClass {
  height: 10rem;
  overflow: scroll;
}

.form-controlxx.ant-select:hover {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
  /* outline: none; */
}

.form-controlxx.ant-select {
  margin-bottom: 0.8rem;
  /* padding: 0.8rem 1rem; */
  box-shadow: none;
  border: none;
  outline: none;
  border-radius: 5px !important;
  -webkit-appearance: none;
}

.form-controlxx .ant-select-selector {
  height: 41px !important;
  border-radius: 5px !important;
}

.form-controlxx .ant-select-selection-placeholder,
.form-controlxx .ant-select-selection-item {
  line-height: 41px !important;
}

.ant-form-item-control-input .ant-form-item-children-icon {
  top: 40% !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.icon-setting {
  width: 27.2px;
  height: 27.2px;
  border-radius: 2.4px;
  background-color: rgba(16, 112, 202, 0.08);
  object-fit: contain;
  margin-left: -1.2rem;
  margin-bottom: 3.2px;
}

.icon-setting:hover,
.icon-setting:active {
  border: 1px solid rgba(16, 112, 202, 0.8);
}

.icon-setting svg {
  width: 16px;
  height: 26.4px;
  padding: 1px;
  color: rgba(16, 112, 202, 0.8);
}

.icon-logo {
  width: 27.2px;
  height: 27.2px;
  /* border-radius: 3px;
  background-color: rgba(16, 112, 202, 0.08); */
  /* object-fit: contain; */
  /* margin-left: -1.5rem; */
}

.icon-logo img {
  width: 16px;
  margin-left: 0.4rem;
  vertical-align: super;
  /* height: 33px; */
  padding: 1px;
  color: rgba(16, 112, 202, 0.8);
}

.icon-choose .ant-form-item-explain {
  margin-left: -1.2rem;
  margin-bottom: 4px;
  /* margin-top: 0.2px; */
  padding-top: 5.6px;
}

.icon-delete-row,
.icon-edit-row {
  width: 27.2px;
  height: 27.2px;
  border-radius: 2.4px;
  background-color: rgba(16, 112, 202, 0.08);
  object-fit: contain;
  /* margin-left: -1.5rem;
  margin-bottom: 4px; */
}

.icon-delete-row:hover,
.icon-delete-row:active {
  /* border: 1px solid rgba(16, 112, 202, 0.8); */
  color: rgba(221, 42, 96, 0.8);
}
.icon-edit-row:hover:not(.not-editing),
.icon-edit-row:active:not(.not-editing) {
  color: rgba(42, 114, 221, 0.8);
}

.icon-delete-row svg,
.icon-edit-row svg {
  width: 16px;
  height: 26.4px;
  padding: 1px;
}

.icon-edit-row.not-editing {
  cursor: not-allowed;
  border: none;
  color: #e0e0e0;
  opacity: 0.2;
}

.tagged-input .ant-form-item-explain > div {
  margin-top: -0.48rem;
}

.tag-list {
  list-style: none;
}

.step-form-table {
  margin-bottom: 0.8rem;
  margin-top: 10px;
}

.step-form .ant-btn {
  height: 30px;
}

.step-form-table ul.ant-pagination {
  display: none;
}
.step-form-table td.ant-table-cell {
  font-family: var(--font-family);
  font-size: 14px; /* 16px */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828ea9;
}

.step-form-table.spt-data tr.ant-table-row td.ant-table-cell:nth-child(4) {
  text-align: center;
}

.step-3-header {
  /* width: 82px;
  height: 20px; */
  display: block;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0c5bab;
  text-transform: capitalize;
  margin-top: 0.8rem;
  margin-bottom: 8.8px;
}

.accredited,
.not-accredited {
  text-transform: capitalize;
  width: 64px;
  height: 16px;
  color: #00ab44;
}

.not-accredited {
  color: #eb0a4ddc;
}

.ant-input-group {
  /* height: 2.7em; */
  /* width: 18em; */
  width: 109%;
  -webkit-transform: translate(-0.969em, -0.72em);
  transform: translate(-0.969em, -0.72em);
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  /* transform: translate(-1.19em, -0.9em); */
}

.form-controlx span.ant-input-group {
  width: 100%;
}
.form-controlx .ant-input-group-wrapper input {
  /* height: 2.16em;
  width: 14.64em; */
}

.form-controlx .ant-input-group > .ant-input:last-child {
  /* height: 30px; */
  height: 41px;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  width: 113.6%;
  box-shadow: none;
  /* border: none !important;
  outline: none; */
}
.form-controlx .ant-input-group:active,
.form-controlx .ant-input-group:focus {
  border-radius: 5px;
  border-color: #40a9ff !important;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
}

.form-controlx.input-addon {
  border: none !important;
}

.form-controlx .ant-input-group-addon:first-child .ant-select-selector,
.form-controlx .ant-input-group-addon:first-child .ant-select {
  outline: none;
}

.form-controlx span.ant-input-group-addon:first-child {
}

.step-form .ant-input-group-addon .ant-select-selector {
  height: 41px !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
/* .step-form .ant-input-group-addon .ant-select-selector:hover,
.step-form .ant-input-group-addon .ant-select-selector:active {
  border: 1px solid #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
} */

.form-controlx span.ant-input-group-addon {
  height: 30px;
  vertical-align: middle;
  border-radius: 5px 0 0 5px;
  /* border: none;
  outline: none; */
}

.form-controlx span.ant-input-group-addon:first-child {
  height: 39px;
  vertical-align: middle;
  -webkit-transform: translate(1.5px);
          transform: translate(1.5px);
  /* border-radius: 5px 0 0 5px; */
  /* border: none;
  outline: none; */
}

.facility-create-result .ant-modal-content {
  /* position: sticky; */
  position: fixed;
  /* top: 25vh; */
  /* left: 15vw; */
  /* right: 15vw; */
  width: 35rem !important;
  height: 33rem;
  /* width: 40vw;
  height: 70vh; */
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.steps-content .step-form .ant-form-item-control .cert-upload-form {
  display: block;
  margin-bottom: 0.8rem;
}

.ant-modal-close {
  display: none;
}

.ant-modal-body {
  height: 100%;
  width: 100%;
}

.facility-form-result {
  height: 100%;
  width: 100%;
}

.ant-result-icon {
  margin-top: 14%;
}

.facility-form-result .ant-result-title {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #828ea9;
}

.facility-form-result .ant-result-title {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.ant-result-icon svg {
  width: 104.8px;
  height: 104.8px;
  object-fit: contain;
}

.ant-modal-boody {
  width: 499.2px;
  height: 80vh;
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.ReactTags__tagInput {
  /* border: solid 1px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  outline: none;
  opacity: 1;
}

.ReactTags__tagInput:hover {
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  opacity: 1;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
  outline: none;
  border-right-width: 1px !important;
}

.ReactTags__tagInput input {
  /* font-size: 11.2px;
  opacity: 1; */
  /* font-family: inherit; */
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: rgba(245, 247, 255, 0.2);
  color: rgba(0, 0, 0, 0.65);
  border: solid 1px rgba(216, 216, 216, 1);
  outline: none;
}

.ReactTags__tagInput input:active {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
  outline: none;
  /* border-right-width: 1px !important; */
}

.form-controlx.tag-field-error {
  border-color: #ff4d4f;
}

.ReactTags__tagInput input:hover {
  border: none;
  outline: none;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
  outline: none;
  /* border-right-width: 1px !important; */
}

.step-form .form-controlx .ant-input-number-input {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 12px;
  width: 20em;
  height: 41px;
  border: none;
  outline: none;
  /* border-radius: 5px; */
  /* border: solid 1px rgba(0, 0, 0, 0.1); */
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(245, 247, 255, 0.2);
}

/* .ant-form-item .ant-input-number-handler-wrap {
  display: none;
} */

.step-form .add-sample-point-btn .ant-form-item-control {
  place-items: flex-end;
}

.icon-choose-modal .ant-modal-content {
  width: 30rem;
  height: 40rem;
  overflow: unset !important;
  /* width: 24rem;
  height: 14.4rem; */
  background-color: #fffffe;
  -webkit-transform: translate(-4.08rem, 7.28rem);
  transform: translate(-4.08rem, 7.28rem);
  /* overflow: scroll; */
}

.icon-choose-modal .ant-modal-content .ant-modal-close {
  display: block;
}

.icon-choose-modal .ant-modal-body {
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
}
.icon-choose-modal .ant-modal-body .ant-row {
  width: 100%;
  overflow: scroll;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.icon-choose-modal .ant-modal-body .ant-row .ant-col-2 {
  max-width: unset;
  /* width: 10rem; */
  -webkit-flex-basis: auto;
          flex-basis: auto;
  /* padding-top: 10px; */
}

.icon-choose-modal .ant-modal-body .ant-row .ant-radio-button-wrapper {
  height: 4rem;
  border: none;
}
.icon-choose-modal .ant-modal-body .ant-radio-group {
  /* margin-left: 0px !important; */
  height: 100%;
}

.icon-choose-modal .ant-modal-body .ant-row {
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 100%;
  padding-top: 8px;
}

.icon-choose-modal .ant-modal-body .ant-row .ant-radio-button-wrapper .icon-logo img {
  width: 3.2rem;
  height: 3.2rem;
}
.icon-choose-modal .ant-modal-body .ant-row .ant-radio-button-wrapper .icon-logo img:hover {
  width: 3.2rem;
  height: 3.2rem;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  border: 1px solid rgba(24, 144, 255, 0.2);
  /* -webkit-box-shadow: 3px 3px 5px 6px rgba(24, 144, 255, 0.2);
  box-shadow: 3px 3px 5px 6px rgba(24, 144, 255, 0.2); */
}

.step-form .suggestionsClass ul,
.step-form .suggestionsClass .ant-list,
.step-form .suggestionsClass .ant-list-item {
  list-style: none !important;
  cursor: pointer;
  padding-top: 5px;
}

.step-form .suggestionsClass ul li {
  padding-top: 5px;
  letter-spacing: 2px;
}

#facilities .ant-steps-item-icon > .ant-steps-icon {
  width: 25.6px;
  height: 25.6px;
  top: 1px;
  font-size: 14px;
}
#facilities .ant-steps-item-icon {
  line-height: 25.6px;
}

ul.ant-table-pagination li.ant-pagination-item,
ul.ant-table-pagination li.ant-pagination-prev,
ul.ant-table-pagination li.ant-pagination-next,
ul.ant-table-pagination li.ant-pagination-prev,
ul.ant-table-pagination li.ant-pagination-next,
ul.ant-table-pagination li.ant-pagination-item {
  height: 25.6px;
  width: 25.6px;
  line-height: 24px;
}

ul.ant-table-pagination .ant-pagination,
ul.ant-table-pagination .ant-pagination {
  font-size: 12px;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.active,
.not-active {
  text-transform: capitalize;
  width: 64px;
  height: 16px;
  color: #00ab44;
}

.not-active {
  color: #eb0a4ddc;
}

/* .facility-overflow__menu {
  background-color: '#f4f4f4';
}

.facility-overflow__menu:hover {
  background-color: '#ffffff';
  color: black;
}

.facility-overflow__menu li:hover {
  -webkit-box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px 2px rgba(0, 0, 0, 0.05);
  color: rgb(4, 46, 46);
  background-color: '#ffffff';
} */

.facility-personnel__others {
  /* style the p */
}

.facility-personnel__notaval {
  /* style the not availae personnel */
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  /* background-color: rgba(225, 234, 243, 0.22); */
  background: unset;
}

.facility-table__popover {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.facility-table__popover .sample-point__popover {
}
.facility-table__popover .address__popover {
}
.ant-modal-content {
  /* position: sticky; */
  position: fixed;
  /* top: 25vh; */
  /* left: 15vw; */
  /* right: 15vw; */
  /* width: 40vw;
  height: 70vh; */
  /* bottom: 15vh; */
  height: 33rem;
  width: 30rem;
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.ant-modal-close {
  display: none;
}

.ant-modal-body {
  height: 100%;
  width: 100%;
}

.ant-select-item {
  font-size: 0.8em;
  padding: 2px 8px;
}

.ant-select-item.ant-select-item-option:not(:first-child) {
  font-weight: normal;
}

.facility-header__main .ant-table {
  font-size: 12px !important;
}

.ant-table-body {
  overflow: unset;
  /* overflow-y: scroll; */
}
.step-form .ant-table-body {
  /* overflow: unset !important; */
  overflow-x: hidden;
  overflow-y: scroll;
}

.facility-details__layout .ant-table-body {
  overflow: unset !important;
}

.facility-table .ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  /* padding-bottom: 22px;
  padding-top: 22px; */
}
.facility-overflow__menu .menu-divider {
  padding: unset;
  background: var(--dark);
}
.facility-overflow__menu .ant-divider-horizontal {
  margin: 10px 0;
  color: var(--dark);
  /* background: var(--dark); */
  width: 100%;
}

div,
label,
p,
li,
strong {
  /* font-family: var(--font-family); */
}

.facility-overview ul {
  padding: 0;
  list-style: none;
}

.facility-overview li {
  padding-bottom: 1%;
}

.facility-overview li:focus {
  outline: none;
  color: #780b1c;
  font-weight: bold;
}

.facility-overview a {
  text-decoration: none;
}

.facility-overview .app {
  height: 50vh;
  width: 100%;
  /* height: 100vh;
  width: 100vw; */
}

.facility-overview #map {
  height: 100%;
}

.facility-overview .filter-button {
  display: none;
  position: absolute;
  text-align: center;
  font-size: 1em;
  color: #fff;
  margin-left: 3vw;
  margin-top: 3vh;
  padding: 1%;
  border: 1px solid #fff;
  background-color: #731725;
  z-index: 3;
}

.facility-overview .listings-container {
  display: none;
  position: absolute;
  /* width: 25vw; */
  /* min-width: 180px; */
  /* margin-left: 3vw; */
  /* margin-top: 10vh; */
  width: 15vw;
  min-width: 100px;
  max-height: 50vh;
  overflow-y: scroll;
  margin-left: 1vw;
  margin-top: 5vh;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 1;
  padding: 2%;
}

.facility-overview .display {
  display: block;
}

.facility-overview .info-box {
  position: absolute;
  z-index: 2;
  width: 30%;
  min-height: 40%;
  margin-right: 3%;
  margin-top: 3%;
  border: 1px solid black;
}

.facility-overview .close-box {
  display: block;
  width: 100%;
  border: none;
  background-color: #fff;
  float: right;
  text-decoration: none;
  color: #731725;
  font-size: 1rem;
  cursor: pointer;
  text-align: right;
}

.facility-overview .info-box-message {
  text-align: justify;
}

.facility-overview .img-container {
  width: 100%;
  margin: auto;
}

.facility-overview .pub-img {
  width: 100%;
  border: 1px solid #000;
}

.facility-overview .overview-box {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #591934;
  border: none;
  padding: 0;
  z-index: 5;
  text-align: center;
  display: table;
  opacity: 0.92;
}

.facility-overview.overview-title {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  opacity: 1;
  text-align: center;
}

.facility-overview.display-none {
  display: none;
}

.facility-overview.filtered-pubs-titles {
  color: #731725;
}

.facility-overview.hint {
  font-size: 0.7em;
  font-style: italic;
}

.facility-details__layout section#pubfilter {
  max-height: 45vh;
}
.facility-details__layout section#pubfilter .listing-results {
  max-height: 27vh;
  overflow: scroll;
}

@media only screen and (max-width: 600px) {
  .facility-overview input {
    border: 1px solid #a1a1a1;
  }

  .facility-overview .info-box {
    width: 50%;
  }

  .facility-overview.close-info-box {
    background-color: #fff;
  }
}

#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}

.facility-details__layout.ant-layout {
  background: unset;
}
.facility-details__layout {
  background: #fff;
}

.ant-layout {
  background: unset;
}

.facility-details__layout aside.ant-layout-sider {
  height: 34.5rem !important;
}

.facility-details__layout .ant-layout-sider-children > ul.ant-menu {
  height: calc(31rem - 55px);
  /* height: calc(31rem - 48px); */
  overflow-y: scroll;
}
.facility-details__layout section {
  height: 31.6rem;
  overflow: hidden;
}
.facility-details__layout main.ant-layout-content {
  overflow-y: scroll !important;
  background-color: white;
  margin: 0px !important;
  width: 100%;
}

.facility-details__layout main.ant-layout-content .site-layout-background {
  padding-top: 0px !important;
  padding-right: 0px !important;
}

.facility-view__reports .compare-accordion {
  width: 48rem;
  height: 30rem;
  overflow: unset;
}

.facility-view__reports .compare-accordion .ant-collapse-header {
  text-align: left;
}

.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content-box
  div.ant-row {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content-box
  .comparison-table
  div.ant-row:nth-child(2) {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.compare-accordion.facility-view__accordion
  .comparison-table
  .ant-table-wrapper
  .ant-table
  .ant-table-container {
  width: 40rem;
  overflow: scroll;
}
.compare-accordion.facility-view__accordion
  .comparison-table
  .ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header {
  /* width: 40rem !important; */
  overflow: unset !important;
}

.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content
  .switch-average-type {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 2rem;
  text-align: -webkit-left;
  text-align: left;
  font-size: 12px;
  /* align-items: center; */
}

.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content
  .switch-average-type
  p {
  width: 5rem;
  font-size: 12px;
}
.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content
  .switch-average-type
  .ant-radio-group
  label.ant-radio-wrapper {
  font-size: 12px;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.loading-outlined svg {
  width: 100%;
  margin: auto;
}
.facility-header__main {
  max-width: 100%;
}

@media screen and (max-width: 1469px) {
  #facilities .facility-header__main .ant-select:first-child,
  #facilities .facility-header__main .ant-select:last-of-type {
    display: none;
  }
}
@media screen and (max-width: 1269px) {
  #facilities .facility-header__main .ant-select:first-child,
  #facilities .facility-header__main .ant-select:last-of-type {
    display: none;
  }
}
@media screen and (max-width: 1069px) {
  #facilities .facility-header__main .ant-select:nth-child(2),
  #facilities .facility-header__main .ant-select:last-child {
    display: none;
  }
}

/* div[class^='ant-'], */
/* div[class*=' ant-'] {
  zoom: 80%;
} */

.hide {
  display: none;
}

.nocursor {
  cursor: not-allowed;
  border: none;
  opacity: 0.2;
  background-color: #e0e0e0 !important;
  z-index: 100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nocursor .qtr-icon img {
  /* background-color: rgba(128, 128, 128, 0.2) !important; */
  -webkit-filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1);
  filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1);
}

div.facility-header__main > div.buttons.left > button {
  margin-left: 0px;
}

.facility-report__standard .ant-modal-content {
  width: 1164px;
  height: 420px;
  /* height: 459px; */
  overflow-y: hidden;
  -webkit-transform: translate(-15em, 2rem);
  transform: translate(-15em, 2rem);
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.facility-report__standard.reporting .ant-modal-content {
  -webkit-transform: translate(-15em, -4rem);
  transform: translate(-15em, -4rem);
}

.onreporting-modal .onreporting-modal__breadcrumb {
  padding-left: 15px;
  margin-top: -25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 1269px) {
  .onreporting-modal .onreporting-modal__breadcrumb {
    padding-left: 15px;
    margin-top: -1px;
    margin-bottom: 15px;
  }
}

.reporting-form__subheading .progress-published .ant-progress {
  -webkit-transform: translateX(-75px);
          transform: translateX(-75px);
}
.reporting-form__subheading .progress-published .ant-progress .ant-progress-outer {
  width: 50%;
}

@media screen and (max-width: 1469px) {
  .facility-report__standard.recording .ant-modal-content {
    width: 55rem !important;
    height: 40rem !important;
    -webkit-transform: translate(-8rem, 2rem) !important;
    transform: translate(-8rem, 2rem) !important;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
  }
  .facility-report__standard .ant-modal-content {
    width: 55rem;
    height: 24rem;
    -webkit-transform: translate(-7rem, -1rem);
    transform: translate(-7rem, -1rem);
  }
  .reporting-form__subheading .progress-published .ant-progress {
    -webkit-transform: translateX(-60px);
            transform: translateX(-60px);
  }
}

@media screen and (max-width: 1569px) {
  .facility-report__standard.recording .ant-modal-content {
    width: 60rem !important;
    /* height: 44rem !important; */
    -webkit-transform: translate(-8rem, 1rem) !important;
    transform: translate(-8rem, 1rem) !important;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
  }
}

.facility-report__standard .ant-modal-header {
  padding: 0;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
}

.facility-report__standard {
  /* width: 65vw;
  height: 45vh; */
  /* top: 18vh; */
  /* left: 20vw; */
  /* transform: translate(calc(-35% + 0.5px), calc(35% + 0.5px)); */
}

.reporting .ant-modal-content {
  width: 1164px;
  height: 750px; /* 713px */
  border-radius: 5px;
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.facility-report__standard.fac-viewing .ant-modal-title {
  padding: 13px 24px;
}

@media screen and (max-width: 1469px) {
  .reporting .ant-modal-content {
    width: 55rem;
    height: 42rem;
    -webkit-transform: translate(-9rem, -3rem);
    transform: translate(-9rem, -3rem);
    /* transform: translate(-6rem, -2rem);
    /* transform: translate(-15em, -2rem); */
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
}

@media screen and (max-width: 1269px) {
  .reporting .ant-modal-content {
    width: 55rem;
    height: 42rem;
    border-radius: 5px;
    /* -webkit-transform: translate(-10%, -1rem);
    -ms-transform: translate(-10%, -1rem);
    transform: translate(-10%, -1rem); */
    -webkit-transform: translate(-5rem, -5rem);
    transform: translate(-5rem, -5rem);
  }
  .facility-card__body .lock-text-desc {
    font-size: 12px;
    width: 90%;
  }
}

@media screen and (max-width: 1400px) {
  .reporting .ant-modal-content {
    width: 55rem;
    height: 42rem;
    border-radius: 5px;
    /* -webkit-transform: translate(-10%, -1rem);
    -ms-transform: translate(-10%, -1rem);
    transform: translate(-10%, -1rem); */
    -webkit-transform: translate(-5rem, -5rem);
    transform: translate(-5rem, -5rem);
  }
  .facility-card__body .lock-text-desc {
    font-size: 10px !important;
    width: 50%;
    overflow: hidden;
    word-wrap: none;
    text-align: left;
  }
}

.recording .ant-modal-content {
  /* width: 979px; */
  height: 750px;
}

.recording .ant-modal-content {
  /* width: 65.5rem;
  height: 40rem;
  -webkit-transform: translate(-15rem, -4rem);
  -ms-transform: translate(-15rem, -4rem);
  transform: translate(-15rem, -4rem); */
}

.recording .ant-modal-body {
  max-height: calc(100% - 126px);
  height: calc(100% - 126px);
  background-color: #ffffff;
}

.recording .onrecording-modal {
  height: 100%;
}
.recording .onrecording-modal > div.ant-row-start {
  -webkit-align-content: space-between;
          align-content: space-between;
  margin-bottom: 0.8rem;
  padding-left: 20px;
  margin-right: unset;
}

.recording .facility-reporting__form {
  margin-top: 1.6rem;
}

.recording ul.ant-table-pagination.ant-pagination {
  display: none;
}

.onrecording-modal .facility-reporting__form .ant-form-item {
  width: 100% !important;
  /* margin-bottom: 1rem !important; */
}

.facility-reporting__form .ant-form-item-control input,
.facility-reporting__form .ant-input-affix-wrapper input {
  text-align: right;
}

.recording .ant-modal-body {
  background-color: #ffffff;
}

.reporting .ant-modal-body {
  background-color: #ffffff;
  height: calc(100% - 126px);
  max-height: calc(100% - 126px);
  /* background-color: rgba(10, 10, 120, 0.05); */
}

.thereport__body .arrow-back::before,
.theadhocreport__body .arrow-back::before {
  content: '\2190';
  z-index: 100;
  /* transform: scale(1.2); */
  font-family: var(--font-family-bold);
  /* font-weight: bolder; */
  width: 200px;
  width: 50px;
  -webkit-transform: translateY(-1rem) !important;
  transform: translateY(-1rem) !important;
  position: absolute;
  cursor: pointer;
}

.onreporting-modal .facility-card__body > .ant-card-body {
  height: 100%;
  padding: 12px;
}

.facility-card__body .lock-text-desc {
  font-family: var(--font-family);
  font-size: 14px;
  color: red;
  width: 100%;
  text-align: center;
  margin-left: 1rem;
}

.facility-report__body .card-lockx {
  height: auto;
  vertical-align: middle;
  text-align: center;
  border-radius: 16px;
  background-color: rgba(77, 124, 254, 0.1);
  margin-bottom: 0.5rem;
}

.facility-report__body .card-lockx p {
  padding: 1.6px;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 11.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #4a90e2;
}

.thereport__body .qtr-lock-icon,
.facility-report__body .qtr-lock-icon {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.thereport__body .qtr-lock-icon {
  width: 100%;
}

.qtr-icon {
  margin-bottom: 0.4rem;
}

.facility-reporting__body.certview-modal {
  height: 580px;
}

.facility-reporting__body.certview-modal body > img {
  width: 100%;
  height: 100%;
}

.facility-report__standard.fac-cert-viewing.subY22 .ant-modal-content {
  height: 22rem !important;
}

.facility-report__header .report-title .not-active,
.facility-report__header .report-title .active {
  font-size: 14px;
  padding-left: 1rem;
  vertical-align: super;
}

.onreporting-modal .qtr-text {
  height: 26.4px;
  line-height: 1.3;
  overflow: hidden;
}

.reporting .ant-form-item-label > label {
  display: inline;
}

.facility-reporting__form .ant-col {
  /* padding: 0 !important; */
  padding-left: 4px;
  padding-right: 0;
}
.facility-reporting__form.submit-report .ant-col {
  padding: 0 !important;
  padding-right: 0;
}

.facility-reporting__form .ant-form-item-label {
  max-width: 100%;
}

.facility-reporting__form .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  content: '';
}
.facility-reporting__form .ant-form-item-label > label::after {
  content: '';
}

.facility-reporting__form .ant-form-item-control-input-content {
  display: -webkit-flex;
  display: flex;
}
.facility-reporting__form .ant-form-item-control-input-content button,
.facility-reporting__form button {
  /* border-radius: 4px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #007ace;
  margin-bottom: -1rem;
  color: #ffffff;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 14px;
  height: 30px; */
}

.facility-reporting__form .ant-form-item-control-input-content button:disabled {
  cursor: not-allowed;
  border: none;
  /* background-color: #e0e0e0; */
  /* opacity: 0.2; */
}

.facility-reporting__form .ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  position: absolute;
  margin-bottom: 16px;
  /* position: relative; */
  -webkit-transform: translate(-0.1rem, 3.8rem);
  transform: translate(-0.1rem, 3.8rem);
}

.facility-reporting__form .ant-form-item {
  width: 120%;
}

.facility-reporting__form .form-controlx {
  /* height: 32.8px; */
  height: 41px;
  width: 100%;
  margin-bottom: 0.8rem;
  padding: 0.64rem 0.8rem;
  border-radius: 5px;
  /* border: solid 1px rgba(0, 0, 0, 0.1); */
  /* background-color: rgba(245, 247, 255, 0.2); */
  border: none !important;
}
.facility-reporting__form .form-controlx input {
  /* font-size: 9.6px; */
  font-family: var(--font-family);
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.facility-reporting__form .form-controlx input[aria-valuenow] {
  font-size: 14px;
}

.facility-reporting__form .form-controlx:active,
.facility-reporting__form .form-controlx:focus,
.facility-reporting__form .form-controlx:hover {
  outline: none;
  border-color: #40a9ff;
  border-right-width: 1px;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.facility-reporting__form label {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 1.6px;
  letter-spacing: 1.13px;
  color: #000000;
}

.facility-reporting__form .ant-form-item-control {
  max-width: 100%;
}

.facility-reporting__form .ant-form-item-control input,
.facility-reporting__form .ant-input-affix-wrapper input {
  width: 100% !important;
}

.reporting-form__heading span {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}
.recording-form__heading span.samplePointName {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.reporting-form__subheading {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828ea9;
}

.reporting-form__subheading.recording-form__subheading {
  font-size: 16px;
  color: #9b9b9b;
}

.reporting-form__subheading .ant-row-start {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #828ea9;
}

.onrecording-modal .ant-row-start .card-lock-col {
  max-width: unset;
  -webkit-flex: unset;
          flex: unset;
}

.onrecording-modal .ant-row-start .card-lock {
  padding: 3px 15px;
  /* width: 67px; */
  /* height: 30px; */
  border-radius: 14.5px;
  background-color: #e7f5ff;
}

.onrecording-modal .ant-row-start .card-lock p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007ace;
}

.reporting-form__subheading.recording-form__subheading span {
  font-size: 16px;
  color: #0cb394 !important;
  /* color: #9b9b9b !important; */
}
.reporting-form__subtext .ant-row-start {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.facility-reporting__form .ant-input-suffix {
  -webkit-align-self: center;
          align-self: center;
}

.form-controlx.ant-input-affix-wrapper input {
  /* height: 41px; */
  height: auto;
  /* margin-bottom: 1rem;
  padding: 0.8rem 1rem; */
  /* border-radius: 5px; */
  background-color: rgba(245, 247, 255, 0.2);
}

.facility-reporting__form .ant-input-affix-wrapper::before {
  /* display: none; */
  width: 120%;
}

.facility-reporting__body .ant-divider-horizontal {
  margin: 0 0 14px 0;
  /* margin-top: 15px; */
}

.recording-form__heading .ant-divider {
  margin-bottom: 30px;
}

.recording-form__table {
  height: 280px;
  max-height: 280px;
}
.published.recording-form__table {
  height: 400px;
  max-height: 400px;
}

@media screen and (max-width: 1269px) {
  .published.recording-form__table {
    height: 300px; /* 18 rem */
    max-height: 300px;
  }
  .recording-form__table,
  .recording-form__table .ant-table-container {
    height: 180px;
    max-height: 180px;
  }
}
@media screen and (max-width: 1469px) {
  .published.recording-form__table {
    /* height: 18rem; */
    max-height: 18rem;
  }
  .recording-form__table,
  .recording-form__table .ant-table-container {
    height: 180px;
    max-height: 180px;
  }
}

.published.recording-form__heading .report-average > td {
  text-align: center;
  padding-left: 10px;
}

.facility-reporting__form .ant-input-number-input {
  /* height: 32.8px; */
  height: 41px;
  position: absolute;
  top: 0;
  right: 0;
  /* margin-bottom: 1rem;
  padding: 0.8rem 1rem; */
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(245, 247, 255, 0.2);
}

.facility-reporting__form .back-button {
  /* border-radius: 5px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #fefefe;
  border: 1px solid #007ace !important;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007ace; */
}

.facility-reporting__form .ant-form-item .ant-input-number-handler-wrap {
  display: none;
}

.onreporting-modal .ant-carousel .slick-slide {
  margin-top: -1rem;
  /* background-color: rgba(10, 10, 120, 0.05); */
}

.onreporting-modal .ant-carousel .slick-dots-bottom {
  bottom: -2rem;
}

.onreporting-modal .ant-carousel .slick-dots li {
  height: 0.4rem;
  width: 2.4em;
  border: 1px solid lightskyblue;
}
.onreporting-modal .ant-carousel .slick-dots li button {
  height: 100% !important;
  border: 1px solid darkslategrey !important;
  background-color: darkslategrey !important;
}
.facility-report__standard.adhoc .slick-dots li {
  /* height: 0.4rem;
  width: 2.4em; */
  border: 1px solid lightskyblue;
}
.facility-report__standard.adhoc .slick-dots li button {
  /* height: 100% !important; */
  border: 1px solid darkslategrey !important;
  background-color: darkslategrey !important;
}

.adhoc-thereport__body input.form-controlx {
  background-color: #ffffff;
}
form.facility-reporting__form button.sample-record-add-btn {
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
  height: 39px;
  /* -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px); */
}
form.facility-reporting__form button.sample-record-add-btn {
  -webkit-align-self: center;
          align-self: center;
  vertical-align: middle;
  /* height: 30px; */
}

.submitBtnReport {
  /* margin-right: 0.8rem; */
  width: 100%;
  padding-right: 15px;
  /* -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem); */
}

@media screen and (max-width: 1269px) {
  .submitBtnReport {
    padding-right: 0px;
  }
}

.facility-reporting__form.submit-report .ant-col-3 {
  margin-right: 12px;
  -webkit-flex: unset;
          flex: unset;
}
.facility-reporting__form.submit-report .ant-col-2 {
  /* margin-right: 12px; */
  -webkit-flex: unset;
          flex: unset;
  max-width: unset;
}

.submitBtnRecord {
  margin-right: 0.8rem;
  width: 100%;
}

.onrecording-modal .ant-row-start > .ant-col.ant-col-offset-1.ant-col-24 {
  height: 27rem;
}
/* .submitBtnRecord {
  position: absolute;
  bottom: 0;
} */

.submitBtnReporting {
  -webkit-transform: translateY(11.4rem);
  transform: translateY(11.4rem);
}

.submitBtnReporting .ant-row-end .ant-col-2 {
  max-width: unset;
}

.submitBtnRecord {
  margin-right: 0.8rem;
  width: 100%;
  /* -webkit-transform: translateY(2rem);
  -ms-transform: translateY(2rem);
  transform: translateY(2rem); */
}

.submitBtnRecording {
  width: 100%;
  height: 30px;
  /* bottom: 0; */
}

.facility-reporting__form .ant-col {
  /* height: 41px; */
}

.qtr-published {
  height: 16px;
  text-align: center;
}

.qtr-published .ant-progress-show-info .ant-progress-outer {
  display: none;
}

.qtr-published .ant-progress-status-success .ant-progress-text {
  /* color: #52c41a */
  color: #2e698b;
}

.qtr-published .ant-progress-text {
  width: 100%;
  margin: 0;
  text-align: center;
}

.qtr-published .ant-progress-text::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 720;
  content: '\2713';
  /* color: #52c41a; */
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.thereport__body .arrow-back::before {
  content: '\2190';
  z-index: 100;
  width: 160px;
  -webkit-transform: translateY(-1.6rem);
  transform: translateY(-1.6rem);
  position: absolute;
  cursor: pointer;
}
.facility-report__body .arrow-back::before {
  content: '\2190';
  z-index: 100;
  width: 160px;
  font-family: var(--font-family-bold);
  -webkit-transform: translateY(-1.6rem);
  transform: translateY(-1.6rem);
  position: absolute;
  cursor: pointer;
}
.thereport__body .arrow-back::before {
  content: '\2190';
  z-index: 100;
  width: 160px;
  -webkit-transform: translateY(-1.6rem) !important;
  transform: translateY(-1.6rem) !important;
  position: absolute;
  cursor: pointer;
}

.adhoc-thereport__body {
  margin-left: 16px;
  font-family: var(--font-family);
}

.adhoc-thereport__body .ant-col {
  max-width: 100%;
}

.adhoc-thereport__body label {
  font-size: 16px;
}

.adhoc-thereport__body .ant-picker-range,
.adhoc-thereport__body .ant-picker {
  width: 100%;
  height: 41px;
  border-radius: 5px;
}

.adhoc-thereport__body .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  content: '';
  display: none;
}

.adhoc-thereport__body .ant-form-item-control-input {
  padding-bottom: 12px;
}
.adhoc-thereport__body .ant-form-item-control-input-content input,
.adhoc-thereport__body .ant-form-item-control-input-content textarea {
  border-radius: 5px;
}

.facility-report__standard.adhoc .add-new-adhoc-btn {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}
.facility-report__standard.adhoc .add-new-adhoc-btn button {
  /* border-radius: 35% 35% 35% 35%; */
  /* border-radius: 50% 20% 20% 50%; */
  /* transform: scale(1.25) translate(-10%, -30%) rotate(90deg); */
}

.facility-report__standard.adhoc .add-new-adhoc-btn svg {
  margin-right: 0px !important;
}
.facility-report__standard.adhoc.add-x2 .ant-modal-content {
  height: 32rem;
}
.facility-report__standard.adhoc.add-x5 .ant-modal-content {
  /* height: 42rem; */
  height: 46rem;
}
.facility-report__standard.adhoc.add-x5
  .ant-modal-content
  .onreporting-modal
  .onreporting-modal__breadcrumb {
  margin-top: -10px;
  margin-bottom: 10px;
}

.facility-report__standard.adhoc .ant-carousel {
  padding-left: 12px;
}
.facility-report__standard.adhoc .ant-carousel .slick-dots-bottom {
  bottom: -1rem;
}

.facility-report__body.onreporting-modal .onreporting-modal__breadcrumb {
  margin-top: -10px;
  margin-bottom: 10px;
}

/* FONT FACE DECLARATION */
@font-face {
  font-family: 'Averta';
  font-weight: 'normal';
  src: url('https://cdn.lubred.net/fonts/AvertaRegular.otf') format('opentype');
}

@font-face {
  font-family: 'Averta Bold';
  font-weight: 'bold';
  src: url('https://cdn.lubred.net/fonts/AvertaBold.otf') format('opentype');
}

/* FONT IMPORTS */

/* Variables */
:root {
  /* Primary Colors */
  --button-primary: #0c5bab;
  --primary: #0072c3;
  --light: #f5f7ff;
  --dark: #2e384d;
  --danger: #fb4b53;
  --white: #ffffff;
  --sidebar-bg: #0c5bab;
  --disable-border: #d9d9d9;
  --disable-bg: #f5f5f5;
  --no-cursor-card: #e0e0e0;
  --disable-color: rgba(0, 0, 0, 0.25);
  --gray: #828ea9;
  --active-green: #00ab44;
  --inactive-red: #eb0a4ddc;
  --result-title: #2e384d;
  --result-subtitle: #828ea9;
  --error-red: red;
  --blue: #2e5bff;
  --menu-toggle-color: var(--primary);

  /* Fonts */
  --font-family: 'Averta', sans-serif;
  --font-family-bold: 'Averta Bold', sans-serif;

  /* Componet Font Sizing */
  --form-label-fs: 14px;
  --form-label-fw: 500px;

  /* Main Layout */
  --sidebar-main-w: 204px;
  --sidebar-min-w: 64px;

  /* Spacing */
  --input-mb: 0.75rem;
  --form-label-mb: 5px;
  --form-label-fs: 14px;
  --sidebar-min-padding-h: 10px;
  --sidebar-min-padding-w: 20px;

  --sidebar-min-padding: var(--sidebar-min-padding-h, --sidebar-min-padding-w);

  /* Components Colors */
  /* --qaqc-highlight: #cbdfb8; */
  --qaqc-highlight: #ebfbdc;
  --non-qaqc-highlight: #ffffff;
  /* --comparing-report-header-color: #699ad0; */
  --comparing-report-header-color: #bfdcfb;
  --comparing-report-header-cell-color: #03382b;
  /* --comparing-report-header-cell-color: #e7ec68; */
  --compare-table-cell-color: var(--dark);
  --compare-table-head-color: #03382b;
  --compare-table-head-bg: #699ad0;
  --regular-table-header-bg: #e2f3f9;
}

/* DEFAULT BASE STYLES */
/* * {
  font-family: Averta, sans-serif;
} */
html {
  font-size: 16px;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  background-color: var(--white);
  font-family: 'Averta', sans-serif, sans-serif;
  font-family: var(--font-family), sans-serif;
  color: #2e384d;
  color: var(--dark);
  font-size: 14px;
  height: 100%;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

button {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

/* FONT SIZES */
.heading {
  font-size: 23px;
  font-weight: bold;
  font-family: 'Averta Bold', sans-serif;
  font-family: var(--font-family-bold);
}

.subheading,
.sub-heading {
  font-size: 16px;
}

.Headers {
  font-family: 'Averta', sans-serif;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e384d;
  color: var(--dark);
}
.Sub-Headers {
  font-family: 'Averta', sans-serif;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e384d;
  color: var(--dark);
}

.small-text {
  font-size: 14px;
}

/* FONT WEIGHT */
.bold {
  font-weight: bold;
}

.inline {
  display: inline;
}

/* COLORS */
.text-white {
  color: #ffffff;
  color: var(--white);
}
.bg-white {
  background-color: #ffffff;
  background-color: var(--white);
}
.text-dark {
  color: #2e384d;
  color: var(--dark);
}
.bg-dark {
  background-color: #2e384d;
  background-color: var(--dark);
}
.text-light {
  color: #f5f7ff;
  color: var(--light);
}
.bg-light {
  background-color: #f5f7ff;
  background-color: var(--light);
}
.text-primary {
  color: #0072c3;
  color: var(--primary);
}
.bg-primary {
  background-color: #0072c3;
  background-color: var(--primary);
}
.text-danger {
  color: #fb4b53 !important;
  color: var(--danger) !important;
}
.bg-danger {
  background-color: #fb4b53;
  background-color: var(--danger);
}
.text-gray {
  color: #828ea9;
  color: var(--gray);
}
.text-blue {
  color: #2e5bff !important;
  color: var(--blue) !important;
}
.text-success {
  color: #00ab44;
  color: var(--active-green);
}
.text-warning {
  color: #f7b500;
}

/* SPACING */
/* Margin */
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mb-1rem {
  margin-bottom: 1rem !important;
}
.mb-2rem {
  margin-bottom: 2rem !important;
}
.mt-1rem {
  margin-top: 1rem !important;
}
.mt-2rem {
  margin-top: 2rem !important;
}
.ml-1rem {
  margin-left: 1rem !important;
}
.ml-2rem {
  margin-left: 2rem !important;
}
.mr-1rem {
  margin-right: 1rem !important;
}
.mr-2rem {
  margin-right: 2rem !important;
}

/* Padding */
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-30 {
  padding-left: 30px;
}

.pb-1rem {
  padding-bottom: 1rem;
}
.pb-2rem {
  padding-bottom: 2rem;
}
.pt-1rem {
  padding-top: 1rem;
}
.pt-2rem {
  padding-top: 2rem;
}
.pl-1rem {
  padding-left: 1rem;
}
.pl-2rem {
  padding-left: 2rem;
}
.pr-1rem {
  padding-right: 1rem;
}
.pr-2rem {
  padding-right: 2rem;
}

/* DISPLAY */
.inline-block {
  display: inline-block;
}
.flex {
  display: -webkit-flex;
  display: flex;
}

/* FLEX */
.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

/* Browser default resets */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 0 !important;
}

/* Buttons */
button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

button:hover {
  opacity: 0.8;
}

.text-center {
  text-align: center;
}

.schedule__container {
  padding: 2rem;
}
.schedule__card {
  border-radius: 5px;
  box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
  border: 1px solid;
  /* background-color: #ffffff; */
  cursor: pointer;
  padding: 1.5rem;
  font-family: var(--font-family);
  border: 0.5px solid #2e5bff;
  background: #f3f7fb;
}
.schedule__card.schedule__card__inactive {
  /* opacity: 0.5 !important; */
  background: #f3f7fb;
  border: none;
  box-shadow: none;
}
.schedule__card.schedule__card__inactive h3 {
  color: #000;
  /* opacity: 0.5; */
}
.schedule__card.schedule__card__inactive .schedule__info__badge {
  /* opacity: 0.3; */
  color: #2e5bff;

  /* background-color: rgba(192, 195, 204, 0.9); */
  /* background: rgba(224, 224, 224, 0.5); */
  background-color: rgba(12, 71, 244, 0.1);
}
.schedule__card.schedule__card__inactive h3 {
  font-weight: bold;
}
.schedule__card.schedule__card__inactive .schedule__icon {
  /* filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1); */
}
.schedule__icon {
  width: 32px;
  height: 35px;
  margin-right: 10px;
}
.schedule__heading h3 {
  font-size: 16px;
  font-family: var(--font-family-bold);
}
.schedule__heading p {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 600;
}
.schedule__info__badge {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1.5rem;
  border-radius: 20px;
  background-color: rgba(12, 71, 244, 0.1);
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 2.5rem;
  font-weight: 600;
  text-align: center;
  color: #2e5bff;
}
.schedule__content {
  /* margin-top: 5rem; */
  font-family: var(--font-family);
}
.schedule__content .ant-card {
  border: none;
}
.schedule__content .ant-card-head {
  padding: 0;
}
.schedule__content .ant-tabs-nav {
  font-size: 14px;
}
.schedule__content .ant-tabs-nav .ant-tabs-tab-active {
  color: #0c47f4;
  font-weight: bold;
}
.schedule__content .ant-tabs-tab {
  color: #9b9b9b;
}
.schedule__content .ant-tabs-top .ant-tabs-ink-bar-animated {
  background-color: #0c47f4;
}
.schedule__content .ant-card-body {
  padding: 24px 0;
}
.schedule__filter {
  width: 450px;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 10px;
}
.schedule__filter .state__filter {
  margin-left: 10px;
}

/* .schedule__filter .ant-select {
    box-shadow: inset 0 -1px 0 0 #edf0f2;
    background-color: #fafafa;
} */

/* .unscheduled__table .ant-table-thead th,
.quarter__table .ant-table-thead th {
    font-size: 14px !important;
    font-family: var(--font-family);
} */
.unscheduled__table .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner,
.quarter__table .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
  background: #0788de;
  border-color: #0788de;
}
.unscheduled__table .ant-table-thead th .ant-checkbox-indeterminate .ant-checkbox-inner::after,
.quarter__table .ant-table-thead th .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: #0788de;
}
.quarter__table .ant-table-thead tr > th {
  background: #f3f7fb;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.unlock__btn {
  color: #fa6400 !important;
  background-color: rgba(250, 100, 0, 0.1) !important;
  border-radius: 25px !important;
  border: none;
  height: 29px;
  line-height: 29px;
  word-break: break-all;
  overflow: hidden;
  padding: 0 0.5rem;
}
.lock__btn {
  color: #2e5bff !important;
  background: rgba(12, 71, 244, 0.1) !important;
  border-radius: 25px !important;
  border: none;
  height: 29px;
  padding: 0 0.5rem;
}
.lock__btn:disabled {
  opacity: 0.3;
  color: #c0c3cc;
  background-color: rgba(12, 71, 244, 0.1);
}

/**** SCHEDULE MODAL ****/
.schedule__modal {
  border-radius: 5px;
}
.schedule__modal .ant-modal-content {
  width: 64rem;
  height: 280px;
  -webkit-transform: translate(-9rem, -1rem);
          transform: translate(-9rem, -1rem);
  padding: 30px 30px 30px 60px;
}
@media screen and (max-width: 1369px) {
  .schedule__modal .ant-modal-content {
    width: 59rem;
    padding: 24px 24px 24px 30px;
  }
}
@media screen and (max-width: 1269px) {
  .schedule__modal .ant-modal-content {
    width: 57rem;
    padding: 24px 24px 24px 30px;
  }
}
@media screen and (max-width: 1069px) {
  .schedule__modal .ant-modal-content {
    width: 53rem;
    padding: 24px 24px 24px 30px;
  }
}
.schedule__modal .ant-modal-header {
  border: none;
  padding: 1rem 0;
  /* padding: 1.5rem 0 0 3.3rem; */
}
.schedule__modal .ant-modal-body {
  padding: 0;
  height: calc(100% - 54px);
}
.schedule__modal .ant-modal-title {
  font-family: var(--font-family);
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
}

.schedule__modal .lock__btn {
  font-size: 14px;
  width: 150px;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 1rem auto;
}
.schedule__modal .schedule__card {
  box-shadow: none;
  cursor: pointer;
  border: none;
  padding: 0;
}

.schedule__modal .ant-col:nth-child(4) .schedule__card .schedule__heading {
  border: none;
}
.schedule__modal .ant-modal-title {
  color: #000;
  font-family: var(--font-famil-bold);
}
.divider__container {
  height: 130px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
}
.schedule__modal .ant-divider {
  height: 70px;
  border-color: #e1e2e8;
}
.schedule__modal .schedule__card button {
  font-weight: 600;
}

.schedule__modal .schedule__card .schedule__heading:hover button {
  box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
}

@media screen and (max-width: 1300px) {
  .schedule__card {
    margin-bottom: 1rem;
    padding: 1rem 1rem;
    min-width: 200px; /*12.5rem */
    height: 140px; /* 8.5rem */
  }
  .lock__btn,
  .unlock__btn,
  .schedule__info__badge {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .schedule__heading h3 {
    font-size: 14px;
    font-weight: 600;
  }

  .schedule__info__badge {
    height: 30px;
    padding: 0 1.5rem;
  }
}

.schedule-page.up-outlined,
.schedule-page.down-outlined {
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.schedule-page.down-outlined {
  -webkit-flex-flow: column;
          flex-flow: column;
}

.schedule-page.down-outlined .down-outlined-icon {
  font-size: 24px;
  margin-left: -1.75rem;
}

.schedule-page.down-outlined .down-outlined-icon:hover,
.schedule-page.down-outlined .down-outlined-icon svg:hover {
  transition: none;
  opacity: 0.5;
  -webkit-animation: none !important;
          animation: none !important;
  -webkit-transform: none !important;
          transform: none !important;
}

.schedule-page.up-outlined .up-outlined-icon {
  font-size: 24px;
  opacity: 0.3;
}

.schedule-page.up-outlined .up-outlined-icon:hover {
  opacity: 1;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.roles__container {
  margin-bottom: 3rem;
}
.roles__container .loader-container {
  text-align: center;
  padding: 4rem 0;
}
.roles__container .role-list {
  border-bottom: 1px solid #f9f9f9;
  padding: 18px 0;
}
.text-right {
  text-align: right;
}
.roles__container .role-name {
  font-size: 15px;
  font-weight: 600;
  /* text-transform: capitalize; */
}

.roles__container .role-description {
  color: #828ea9;
  font-size: 15px;
  text-transform: capitalize;
}
.roles__container .role-action {
  display: inline-block;
  z-index: 1000;
}
.roles__container .role-action > .ant-icon {
  z-index: 1;
}

.edit__role-btn {
  border: none !important;
}
.edit__role-btn:hover {
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background: rgba(12, 91, 171, 0.6);
}
.delete__role-btn {
  color: #ec4c47;
  background: #fff;
  border: none;
  padding: 5px 10px;
}
.delete__role-btn:hover {
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background: rgba(236, 76, 71, 0.5);
}

.role__popover .ant-popover {
  padding: 20px 16px;
}
.role__popover .ant-popover > .ant-popover-content {
  width: 250px;
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: -2px 2px 20px -7px rgba(0, 0, 0, 0.1);
}
.box {
  margin-bottom: 9px;
  font-size: 14px;
}
.box > .dark {
  color: #000;
}
.box > .light {
  color: #9b9b9b;
  float: right;
}
.role-badge {
  float: right;
  color: #007ace;
  background: #e7f5ff;
  padding: 5px 6px;
  border-radius: 10px;
  font-size: 9px;
  font-family: var(--font-family);
}
.role-description {
  font-size: 14px;
  color: #9b9b9b;
  letter-spacing: -0.1px;
  text-transform: capitalize;
}

.role__popover {
  margin-right: 10px;
}
.actions__column {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
  /* justify-content: flex-end; */
}

.edit__role__modal {
  width: 950px !important;
  height: 850px !important;
  border-radius: 5px;
  font-family: var(--font-family);
  margin-bottom: 3rem;
}
.edit__role__modal .ant-modal-content {
  height: inherit;
  width: 950px !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  border-radius: 5px;
  -webkit-transform: translateY(-4rem);
          transform: translateY(-4rem);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

@media screen and (max-width: 1469px) {
  .edit__role__modal .ant-modal-content {
    height: 700px;
    width: 950px !important;
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
}

@media screen and (max-width: 1269px) {
  .edit__role__modal .ant-modal-content {
    height: 650px;
    width: 950px !important;
    -webkit-transform: translateY(-5.5rem);
            transform: translateY(-5.5rem);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
}

.edit__role__modal .ant-modal-body {
  height: calc(100% - 114px);
  max-height: calc(100% - 114px);
}
.edit__role__modal .edit__role__content {
  padding: 0 38px;
}
.edit__role__header h3 {
  font-family: var(--font-family-bold);
  color: #4a4a4a;
  line-height: 1;
}
.edit__role__header p {
  font-size: 18px;
  font-weight: normal;
}
.edit__role__modal .ant-modal-title {
  font-weight: 600;
  /* font-family: var(--font-family-bold); */
  padding: 13px 38px;
}
.edit__role__modal .ant-form-item-label {
  font-weight: 600;
}

.edit__role__modal .edit__role-form .ant-form-item {
  margin-bottom: 10px;
}

.edit__role-form .form-group.buttons {
  margin-top: 2rem;
}

.permissions-container {
  height: 350px;
  /* display: flex;
  flex: 1;
  flex-direction: column; */
  width: 100%;
  overflow: auto;
  display: inline-grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

@media screen and (max-width: 1469px) {
  .permissions-container {
    height: 250px;
  }
}
@media screen and (max-width: 1269px) {
  .permissions-container {
    height: 200px;
  }
}

@-webkit-keyframes blink {
  78% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

  92% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes blink {
  78% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

  92% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.loading-permissions span:nth-child(1) {
  -webkit-animation: blink linear infinite 1s;
          animation: blink linear infinite 1s;
}
.loading-permissions span:nth-child(2) {
  -webkit-animation: blink linear infinite 2s;
          animation: blink linear infinite 2s;
}
.loading-permissions span:nth-child(3) {
  -webkit-animation: blink linear infinite 3s;
          animation: blink linear infinite 3s;
}

.edit__role__modal .permission-name {
  text-transform: capitalize;
  margin-bottom: 20px !important;
  font-size: 16px;
}
.edit__role-form .ant-checkbox-group-item + .ant-checkbox-group-item {
  display: block;
}
.edit__role-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
}
.edit__role-form .ant-checkbox-wrapper {
  margin-bottom: 17px;
  display: block;
}
.edit__role-form .ant-checkbox-wrapper > span {
  color: #828ea9;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 12px;
}
.edit__role-form .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.edit__role__modal .permissions-container h3 {
  margin: 1rem 0;
  font-weight: bold;
}
.edit__role-form .ant-form-item-explain {
  margin-top: 0 !important;
}
.edit__role-form .error-text {
  font-size: 16px;
  text-align: center;
}
.edit__role-form .ant-form-item-required::before {
  display: none !important;
}
.edit__role-form .ant-checkbox-wrapper .ant-form label {
  font-size: 13px;
}
.edit__role-form .ant-form-item-label label::before {
  display: none;
}
.edit__role-form input {
  border: 1px solid rgba(216, 216, 216, 0.1);
  height: 41px;
  background: rgba(216, 216, 216, 0.2);
}
.edit__role-form input:focus {
  border-color: #0052cc;
}
.edit__role-form input::-webkit-input-placeholder {
  color: #d8d8d8;
}
.edit__role-form input::-ms-input-placeholder {
  color: #d8d8d8;
}
.edit__role-form input::placeholder {
  color: #d8d8d8;
}

.confirmation__modal {
    width: 600px !important;
    height: 400px !important;
    background: #fff;
    font-family: "Akkurat";
}
.confirmation__modal .ant-modal-content {
    width: inherit;
    height: inherit;
}
.confirmation__modal .modal__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: inherit;
}
.modal__content p {
    font-weight: 600;
    font-size: 20px;
    margin: 2rem 0;
}
.modal__content button {
    background: #0c5bab;
    color: #fff;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    margin-top: 2rem;
    font-weight: 600;
    font-size: 18px;
}

.create__role__modal {
  width: 950px !important;
  height: 850px !important;
  border-radius: 5px;
  font-family: var(--font-family);
  margin-bottom: 2rem;
}

.create__role__modal .ant-modal-content {
  height: inherit;
  width: 950px !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  border-radius: 5px;
  -webkit-transform: translateY(-4rem);
          transform: translateY(-4rem);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.create__role__modal .ant-modal-body {
  height: calc(100% - 114px);
  max-height: calc(100% - 114px);
}

.create__role__modal .oncreaterole-modal {
  padding: 0px 38px;
}
.create__role__modal .oncreaterole-modal .create-role-form .ant-form-item {
  margin-bottom: 10px;
}

.create__role__modal
  .oncreaterole-modal
  .create-role-form
  .ant-form-item-label
  > label.ant-form-item-required::before {
  display: none;
}

@media screen and (max-width: 1469px) {
  .create__role__modal .ant-modal-content {
    height: 700px;
    width: 950px !important;
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
}

@media screen and (max-width: 1269px) {
  .create__role__modal .ant-modal-content {
    height: 650px;
    width: 950px !important;
    -webkit-transform: translateY(-5.5rem);
            transform: translateY(-5.5rem);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
}

.create__role__modal .ant-modal-title {
  padding: 13px 38px;
}

.create__role__modal .ant-modal-header {
  /* padding: 0 40px 29px 20px !important;
  border-bottom: none; */
}

.create__role__modal-header .heading {
  font-family: var(--font-family-bold);
  font-size: 23px;
  margin-bottom: 10px;
  line-height: 1;
  color: #4a4a4a;
}

.create__role__modal-header h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px !important;
}

.create__role__modal-header p.headingText {
  color: #828ea9;
  font-size: 18px;
  margin-bottom: 10px;
  font-family: var(--font-family);
}

.create__role__modal .ant-modal-body {
  border: none;
}

.create-role-form label {
  color: #2e384d;
  letter-spacing: normal;
  font-weight: bold;
  font-size: 14px;
}

.create-role-form input {
  border: 1px solid rgba(216, 216, 216, 0.1);
  background: rgba(216, 216, 216, 0.2);
}
.create-role-form input:focus {
  border-color: #0052cc;
}
.create-role-form input::-webkit-input-placeholder {
  color: #d8d8d8;
}
.create-role-form input::-ms-input-placeholder {
  color: #d8d8d8;
}
.create-role-form input::placeholder {
  color: #d8d8d8;
}

.permissions-container {
  height: 350px;
  /* display: flex;
  flex: 1;
  flex-direction: column; */
  width: 100%;
  overflow: auto;
  display: inline-grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

@media screen and (max-width: 1469px) {
  .permissions-container {
    height: 250px;
  }
}
@media screen and (max-width: 1269px) {
  .permissions-container {
    height: 200px;
  }
}

@-webkit-keyframes blink {
  78% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

  92% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes blink {
  78% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }

  92% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.loading-permissions span:nth-child(1) {
  -webkit-animation: blink linear infinite 1s;
          animation: blink linear infinite 1s;
}
.loading-permissions span:nth-child(2) {
  -webkit-animation: blink linear infinite 2s;
          animation: blink linear infinite 2s;
}
.loading-permissions span:nth-child(3) {
  -webkit-animation: blink linear infinite 3s;
          animation: blink linear infinite 3s;
}

.create-role-form .ant-checkbox-group-item + .ant-checkbox-group-item {
  display: block;
}
.permission-name {
  text-transform: capitalize;
  margin-bottom: 20px !important;
  font-size: 16px;
}
.create-role-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
}
.create-role-form .ant-checkbox-wrapper {
  margin-bottom: 17px;
  display: block;
}
.create-role-form .ant-checkbox-wrapper > span {
  color: #828ea9;
  font-weight: normal;
  text-transform: capitalize;
}
.create-role-form .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.create-role-form .form-group.buttons {
  margin-top: 2rem;
}

.create-role-form input {
  border: 1px solid rgba(216, 216, 216, 0.1) !important;
  background: rgba(216, 216, 216, 0.2) !important;
  height: 41px;
}
.create-role-form input:focus {
  border-color: #0052cc !important;
}
.create-role-form input::-webkit-input-placeholder {
  color: #d8d8d8;
}
.create-role-form input::-ms-input-placeholder {
  color: #d8d8d8;
}
.create-role-form input::placeholder {
  color: #d8d8d8;
}

.create-role-form .save__form {
  background: #007ace;
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
}
.save__form:hover {
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
}
.create-role-form .cancel-btn {
  margin-left: 10px;
  background: transparent;
  color: #005bab;
  border-radius: 2px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1.5rem;
}
.create-role-form .cancel-btn {
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
}
.error-text {
  color: red;
}
.permissions-container h3 {
  /* margin: 1rem 0; */
  font-weight: bold;
  font-family: var(--font-family-bold);
}

.permissions__name > span {
  font-size: 12px;
}

.create-role-form .ant-form-item-label label::before {
  display: none;
}

.admin__reports {
  padding: 2rem 0 2rem 2rem;
  font-family: var(--font-family);
  color: var(--dark);
}

.compliance__report__content .trv-content.k-splitter .k-pane.trv-document-map-splitter {
  width: 100% !important;
  transition: all ease-in-out;
}
.compliance__report__content .trv-pages-area.k-widget.k-pane.printpreview {
  width: 100% !important;
  transition: all ease-in-out;
}

.admin__reports .ant-tabs-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* width: 300px; */
  /* width: max-content; */
  min-height: 900px;
}

.admin__reports .ant-tabs-tabpane.ant-tabs-tabpane-active {
  transition: visibility ease;
}

.admin__reports.generated .ant-tabs-content {
  min-height: unset;
}

.ant-tabs.audit__tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left !important;
  font-size: 16px;
  margin-right: 1rem;
}

.ant-tabs.audit__tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: var(--dark);
  font-family: var(--font-family-bold);
  background: #dff1ff;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
.ant-tabs.audit__tabs .ant-tabs-nav .ant-tabs-tab:nth-child(1).ant-tabs-tab-active {
  background: #fff;
}
.ant-tabs.audit__tabs .ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--sidebar-bg);
}

.ant-tabs.audit__tabs .ant-tabs-ink-bar {
  background-color: var(--sidebar-bg) !important;
  display: none !important;
}
.ant-tabs.audit__tabs .ant-tabs-nav .ant-tabs-tab {
  border-bottom: 1px solid #f9f9f9;
}
.admin__tab {
  height: calc(100vh - 85px);
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: all ease-in;
}

.compliance__date__filter {
  border: none !important;
}
.compliance__filter {
  width: 70%;
}
.compliance__filter .ant-select-selector {
  border: none !important;
}
.compliance__filter.ant-select-focused.ant-select-single.ant-select-selector {
  box-shadow: none !important;
  outline: none;
}
.compliance__filter .ant-select-arrow {
  left: 0;
}
.compliance__divider {
  height: 1.5rem !important;
  color: #979797 !important;
}

.compliance__report__content {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transition: all ease-in-out;
  /* margin-top: 0px !important; */
  /* padding-top: 0px !important; */
}

.compliance__report__content .audit__summary {
  margin-top: 0px !important;
}

.compliance__report__content .trv-report-viewer {
  position: absolute;
  transition: all ease-in-out;
}
.compliance__report__content,
.audit__log__content,
.noncompliance__report__content,
.emission__stat__content,
.density__grid__content {
  box-shadow: 0 7px 13px 0 rgba(149, 156, 182, 0.15);
  min-height: calc(100vh - 120px);
  border: solid 0.9px #eaebf0;
  /* padding: 2rem; */
}

/* AUDIT LOG */
.audit__log__content .logo__wrap img,
.compliance__report__content .logo__wrap img,
.noncompliance__report__content .logo__wrap img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.audit__summary p span {
  margin-right: 3px;
}

.edit__zone__btn {
  background: transparent !important;
}
.edit__zone__modal {
  font-family: var(--font-family);
  color: #2e384d;
  height: 300px !important;
}
.edit__zone__modal .ant-modal-content {
  width: inherit;
  height: inherit;
}
.edit__zone__modal .ant-modal-title {
  font-weight: 600;
  font-family: var(--font-family-bold);
  font-size: 18px;
}
.edit__zone__modal .add-more-container {
  margin-bottom: 1rem;
}
.edit__zone__form .save-btn {
  border: none;
  background: #0c5bab;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  margin-right: 10px;
  margin-top: 1.5rem;
}
.edit__zone__form .cancel-btn {
  color: #0c5bab;
  border: none;
  background: transparent;
  padding: 0.5rem 1.5rem;
  margin-top: 1.5rem;
}
.edit__zone__form .ant-form-item-required::before {
  display: none !important;
}
.edit__zone__form .ant-form-item-label label {
  font-weight: 600;
  font-size: 14px;
}
.edit__zone__form .ant-form-item-label label:before {
  display: none;
}

.edit__zone__form .ant-form-item-explain {
  margin-top: 0;
}

.editable-row td:nth-child(3) {
  text-align: right;
}
.no-padding-x {
  padding: 24px 0 !important;
  padding-top: 0 !important;
}
.flex-column-end {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}
.lookup__title {
  padding-left: 30px !important;
}
.add__zone__modal {
  font-family: var(--font-family);
  border-radius: 5px;
  height: 300px !important;
}
.add__zone__modal .ant-modal-content {
  border-radius: 5px;
  height: inherit;
}
.add__zone__modal .ant-modal-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
}
.add__zone__modal .ant-form-item-required {
  font-weight: 600;
}

.add__zone__modal .ant-form-item-required::before {
  display: none !important;
}

.flex-column-end {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.search__states__input .ant-input-affix-wrapper {
  margin-bottom: 20px;
}

/* ADD STATE MODAL */
.add__state__modal {
  border-radius: 10px;
  height: 500px !important;
  font-family: var(--font-family);
}
.add__state__modal .ant-modal-content {
  border-radius: 10px;
  height: inherit;
}
.add__state__modal .ant-modal-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
}
.state__lookup__form .ant-form-item-required {
  /* font-weight: 600; */
}
.state__lookup__form .ant-form-item-required::before {
  display: none !important;
}

/* EDIT STATE MODAL */
.edit__state {
  height: 450px !important;
  width: 700px !important;
}
.edit__state .ant-modal-content {
  width: inherit;
  height: inherit;
  position: -webkit-sticky !important;
  position: sticky !important;
  border-radius: 13px;
  font-family: var(--font-family);
}
.edit__state .ant-modal-header {
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  padding: 1rem 1.5rem;
}
.edit__state .ant-modal-title {
  font-size: 18px;
  font-family: var(--font-family-bold);
}

.edit__state-form .ant-form-item-label label {
  /* font-weight: 600; */
}

.edit__state-form .ant-form-item-required::before {
  display: none !important;
}

.state__lookup__row td:nth-child(5) {
  text-align: right;
}

.transparent__bg {
  background: transparent !important;
}

.state__lookup__form .ant-form-item,
.edit__state-form .ant-form-item {
  margin-bottom: unset;
}

.header-span100 .ant-col .ant-row {
  width: 100%;
}

.add__state__modal .ant-modal-content,
.edit__state .ant-modal-content {
  width: 700px;
  border-radius: 5px;
  /* height: 400px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.state__lookup__form .ant-form-item-explain,
.edit__state-form .ant-form-item-explain {
  text-align: left;
}

.lga__lookup {
  padding-left: 2rem;
  font-family: var(--font-family);
  color: #2e384d;
}

.lga__lookup__row td:nth-child(4) {
  text-align: right !important;
}
/* CREATE LGA MODAL */
.create__lga__modal {
  height: 350px !important;
  border-radius: 10px !important;
  font-family: var(--font-family);
}
.create__lga__modal .ant-modal-content {
  height: inherit;
  border-radius: 10px !important;
}

.create__lga__modal .ant-modal-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
}

.create__lga__modal .ant-form-item-required {
  /* font-weight: 600; */
}
.create__lga__modal .ant-form-item-required::before {
  display: none !important;
}

.create__lga__modal .ant-form-item-explain {
  margin-top: 0;
}
.edit__lga__modal {
  width: 600px !important;
  height: 350px !important;
  font-family: var(--font-family);
  border-radius: 10px;
  background: #fff;
}
.pageheader__start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.edit__lga__modal .ant-modal-content {
  width: inherit;
  height: inherit;
  border-radius: 10px;
}
.edit__lga__modal .ant-modal-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
}
.align-center {
  -webkit-align-items: center;
          align-items: center;
}

.edit__Lg-form .ant-form-item-label label {
  /* font-weight: 600; */
}
.edit__Lg-form .ant-form-item-required:before {
  display: none !important;
}

.edit__Lg-form .ant-form-item-explain {
  margin-top: 0 !important;
}

.create__lga__modal .ant-modal-content,
.edit__lga__modal .ant-modal-content {
  width: 700px;
  border-radius: 5px !important;
  /* height: 400px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.lga__lookup__form .ant-form-item,
.edit__Lg-form .ant-form-item {
  margin-bottom: unset;
}

.lga__lookup__form .form-controlxx.ant-select,
.edit__Lg-form .form-controlxx.ant-select {
  margin-bottom: unset;
}

.lga__lookup__form input,
.edit__Lg-form input {
  margin-bottom: unset !important;
}

.lga__lookup__form .ant-form-item-explain,
.edit__Lg-form .ant-form-item-explain {
  text-align: left;
}

.add__sector__button {
    margin-left: 0 !important;
}
.edit__sector__btn {
    border: none !important;
    background: transparent;
}

.sector__lookup__row td:nth-child(3) {
    text-align: right !important;
}
.ant-modal.create__sector__modal {
    height: 300px !important;
    font-family: var(--font-family);
}
.create__sector__modal .ant-modal-title {
    font-family: var(--font-family-bold);
    font-size: 18px;
}
.create__sector__modal .ant-modal-content {
    height: inherit;
    border-radius: 13px;
}

.create__sector__modal .ant-form-item-label label {
    font-weight: 600;
}

.edit__sector__modal {
    height: 300px !important;
    font-family: var(--font-family);
}
.edit__sector__modal .ant-modal-content {
    border-radius: 13px;
    height: inherit;
}
.edit__sector__modal .ant-modal-title {
    font-family: var(--font-family-bold);
}

.edit__sector__modal .ant-form-item-label label {
    font-weight: 600;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: rgba(34, 29, 28, 0.3);
}

.text-center {
  text-align: center;
}

.menu-toggle {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  z-index: 120;
  display: inline-block;
}

.menu-toggle span {
  padding: 8px;
}

/* vertical-align: middle;
  padding: 2px;
  width: 24px;
  height: 24px;
} */

.main-panel {
  overflow: hidden;
  height: 100%;
  /* width: calc(100% - 204px) !important; */
}

#facilities {
  position: relative;
  overflow: hidden;
}

#reports {
  position: relative;
  overflow: hidden;
}
.transparent__bg {
  background: transparent;
}

@media screen and (max-width: 769px) {
  .SideNav-Expand {
    width: 150px !important;
  }

  .SideNav-Collapse {
    width: 100px !important;
  }

  .SideNav {
    height: 100%;
    width: auto;
    zoom: 80%;
    padding-right: 10px;
    font-size: 12px;
  }

  .main-panel {
    width: calc(100% - 150px) !important;
  }

  #facilities {
    width: calc(100% - 100px);
    position: relative;
    overflow: scroll;
  }

  #reports {
    width: calc(100% - 100px);
    position: relative;
    overflow: scroll;
  }
  html {
    zoom: 80%;
    height: auto;
  }

  /*
  html {
    zoom: 80%;
    height: 100px;
  } */
}

.text-center {
  text-align: center;
}

.small-heading {
  font-size: 18px;
  font-family: var(--font-family-bold);
  color: var(--dark);
  margin-top: 20px !important;
  /* margin-top: 43px !important; */
}

.map {
  margin-top: 5rem;
}

.error-text {
  color: var(--error-red);
  font-size: 12px;
  font-weight: 500;
}

.loader {
  text-align: center;
}

.signup__loader {
  border: 1px solid;
  height: 100vh;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.main-panel header {
  /* margin-top: 8px; */
}

.Main-Content {
  height: 100%;
}

.spin-fullpage {
  z-index: 100;
}

.spin-fullpage span {
  height: 100%;
}
.d-flex {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
}

table {
  table-layout: auto !important;
}

