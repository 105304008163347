/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@import "~antd/dist/antd.css";
@import "./styles/index.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: rgba(34, 29, 28, 0.3);
}

.text-center {
  text-align: center;
}

.menu-toggle {
  background: white;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 120;
  display: inline-block;
}

.menu-toggle span {
  padding: 8px;
}

/* vertical-align: middle;
  padding: 2px;
  width: 24px;
  height: 24px;
} */

.main-panel {
  overflow: hidden;
  height: 100%;
  /* width: calc(100% - 204px) !important; */
}

#facilities {
  position: relative;
  overflow: hidden;
}

#reports {
  position: relative;
  overflow: hidden;
}
.transparent__bg {
  background: transparent;
}

@media screen and (max-width: 769px) {
  .SideNav-Expand {
    width: 150px !important;
  }

  .SideNav-Collapse {
    width: 100px !important;
  }

  .SideNav {
    height: 100%;
    width: auto;
    zoom: 80%;
    padding-right: 10px;
    font-size: 12px;
  }

  .main-panel {
    width: calc(100% - 150px) !important;
  }

  #facilities {
    width: calc(100% - 100px);
    position: relative;
    overflow: scroll;
  }

  #reports {
    width: calc(100% - 100px);
    position: relative;
    overflow: scroll;
  }
  html {
    zoom: 80%;
    height: auto;
  }

  /*
  html {
    zoom: 80%;
    height: 100px;
  } */
}

.text-center {
  text-align: center;
}

.small-heading {
  font-size: 18px;
  font-family: var(--font-family-bold);
  color: var(--dark);
  margin-top: 20px !important;
  /* margin-top: 43px !important; */
}

.map {
  margin-top: 5rem;
}

.error-text {
  color: var(--error-red);
  font-size: 12px;
  font-weight: 500;
}

.loader {
  text-align: center;
}

.signup__loader {
  border: 1px solid;
  height: 100vh;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-panel header {
  /* margin-top: 8px; */
}

.Main-Content {
  height: 100%;
}

.spin-fullpage {
  z-index: 100;
}

.spin-fullpage span {
  height: 100%;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

table {
  table-layout: auto !important;
}
