.roles__container {
  margin-bottom: 3rem;
}
.roles__container .loader-container {
  text-align: center;
  padding: 4rem 0;
}
.roles__container .role-list {
  border-bottom: 1px solid #f9f9f9;
  padding: 18px 0;
}
.text-right {
  text-align: right;
}
.roles__container .role-name {
  font-size: 15px;
  font-weight: 600;
  /* text-transform: capitalize; */
}

.roles__container .role-description {
  color: #828ea9;
  font-size: 15px;
  text-transform: capitalize;
}
.roles__container .role-action {
  display: inline-block;
  z-index: 1000;
}
.roles__container .role-action > .ant-icon {
  z-index: 1;
}

.edit__role-btn {
  border: none !important;
}
.edit__role-btn:hover {
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background: rgba(12, 91, 171, 0.6);
}
.delete__role-btn {
  color: #ec4c47;
  background: #fff;
  border: none;
  padding: 5px 10px;
}
.delete__role-btn:hover {
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background: rgba(236, 76, 71, 0.5);
}

.role__popover .ant-popover {
  padding: 20px 16px;
}
.role__popover .ant-popover > .ant-popover-content {
  width: 250px;
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: -2px 2px 20px -7px rgba(0, 0, 0, 0.1);
}
.box {
  margin-bottom: 9px;
  font-size: 14px;
}
.box > .dark {
  color: #000;
}
.box > .light {
  color: #9b9b9b;
  float: right;
}
.role-badge {
  float: right;
  color: #007ace;
  background: #e7f5ff;
  padding: 5px 6px;
  border-radius: 10px;
  font-size: 9px;
  font-family: var(--font-family);
}
.role-description {
  font-size: 14px;
  color: #9b9b9b;
  letter-spacing: -0.1px;
  text-transform: capitalize;
}

.role__popover {
  margin-right: 10px;
}
.actions__column {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  /* justify-content: flex-end; */
}
