.confirmation__modal {
    width: 600px !important;
    height: 400px !important;
    background: #fff;
    font-family: "Akkurat";
}
.confirmation__modal .ant-modal-content {
    width: inherit;
    height: inherit;
}
.confirmation__modal .modal__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
}
.modal__content p {
    font-weight: 600;
    font-size: 20px;
    margin: 2rem 0;
}
.modal__content button {
    background: #0c5bab;
    color: #fff;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    margin-top: 2rem;
    font-weight: 600;
    font-size: 18px;
}
