.reset__password-form {
    font-family: var(--font-family);
}

.reset__password-form .form-header {
    margin-bottom: 40px !important;
}
.reset__password-form h1 {
    font-size: 36px;
}
.reset__password-form p {
    color: #828ea9;
    font-size: 16px;
}
.text-center {
    text-align: center;
}
.reset__password-form .form-group {
    margin-bottom: 20px !important;
}
.reset__password-form .form-group label {
    color: #5a7184;
    font-size: 16px;
}
.error-text {
    color: red;
    font-size: 12px;
    font-weight: 500;
}
.reset__password-form button {
    border-radius: 8px;
    font-weight: 500;
    /* margin-top: 50px; */
}
.reset__password-form .ant-input-password {
    height: 50px;
    border: solid 1px #c3cad9;
    background-color: #ffffff;
    padding: 18px 20px;
}
.reset__password-form .ant-input-affix-wrapper {
    max-height: 50px;
}
.reset__password-form .ant-input-password > .ant-input {
    margin-top: -14px;
    height: 40px;
}
.reset__password-form .ant-input-affix-wrapper:hover {
    border-color: #0c5bab;
}
.reset__password-form .ant-input-affix-wrapper :focus {
    border-color: #0c5bab;
    box-shadow: none !important;
    outline: none;
}
.ant-input-affix-wrapper .ant-input-suffix {
    display: flex;
    align-items: center;
}
.ant-input-affix-wrapper.ant-input-password {
    border-radius: 8px;
}

/* RESET MODAL */
.reset__modal {
    background: #fff;
    width: 600px !important;
    height: 400px;
    font-family: var(--font-family);
    text-align: center;
}

.reset__modal .ant-modal-content {
    height: inherit;
    width: inherit;
    position: sticky !important;
}

.reset__modal h3 {
    font-size: 18px;
    margin-top: 2rem !important;
}
.reset__modal p {
    color: #828ea9;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2rem !important;
}
.reset__modal img {
    width: 200px;
}
.reset__modal .footer {
    width: 100%;
    text-align: center;
}
.reset__modal button {
    padding: 0 40px;
    height: 40px;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}
