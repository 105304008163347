/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.viewing .nocursor {
  cursor: pointer;
}

.facility-report__standard.viewing {
  /* justify-content: center; */
}

.viewing .ant-carousel {
  /* this affects justify content center */
  width: 100%;
}

.onviewing-modal-body,
.oncompare-modal-body {
  margin-left: 55px;
  /* margin-right: 79px; */
}

.onviewing-modal-body .ant-carousel {
  margin-bottom: 10px; /* 24px */
}

.viewing .ant-carousel .ant-row {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: start;
}

.viewing-carousel .facility-card__body.ant-card {
  width: 180px; /* 172px; */
  height: 150px; /* 140px; */
  border-radius: 3.5px;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  margin-bottom: 69px;
}

@media screen and (max-width: 1400px) {
  .viewing-carousel .facility-card__body.ant-card {
    width: 172px !important; /* 172px; */
  }
  .viewing-carousel .facility-card__body.ant-card .qtr-text {
    margin-bottom: unset !important;
  }
}

.viewing .facility-report__body .ant-card {
  /* width: auto !important; */
}
.viewing .facility-report__body .ant-card .qtr-subtext {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-family: var(--font-family);
  font-size: 11.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.viewing .facility-report__body .ant-card .qtr-text {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-family: var(--font-family);
  font-size: 11.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: unset;
}
.facility-report__standard {
  /* margin- */
}

.reporting.viewing .ant-modal-content {
  /* width: 90vw !important;
  height: 90vh;
  transform: translate(-16rem, -3rem); */
  width: 923px !important;
  height: 750px !important;
  /* -webkit-transform: translate(-15rem, -4rem);
  -ms-transform: translate(-15rem, -4rem);
  transform: translate(-15rem, -4rem); */
  transform: translate(-12.5rem, -1.5rem);
  backface-visibility: hidden;
}

@media screen and (max-width: 1269px) {
  .reporting.viewing .ant-modal-content {
    transform: translate(-12rem, -5.5rem);
    backface-visibility: hidden;
    /* transform: translate(-12rem, -3rem); */
    /* transform: translate(-5.5rem, -5.5rem); */
  }
}

.reporting.viewing .ant-modal-body {
  height: 100%;
  max-height: 100%;
}

/* .facility-report__body .onviewing-modal {
  height: 100% !important;
} */

.onviewing-modal .ant-row-start {
  margin-bottom: 1.6rem;
}

.reporting ul.ant-table-pagination.ant-pagination {
  display: none;
}

.onviewing-modal .facility-reporting__form .ant-form-item {
  width: 100% !important;
  /* margin-bottom: 1rem !important; */
}

.viewing .ant-modal-body {
  background-color: #ffffff;
}

.viewing .ant-modal-content {
  /* height: 44.9rem; */
  /* height: 40rem; */
  /* width: 44.08rem !important; */
  /* width: 65.1rem; */
  /* -webkit-transform: translate(-8rem, 0);
  -ms-transform: translate(-8rem, 0);
  transform: translate(-8rem, 0);
  border-radius: 5px !important; */
}

.onviewing-modal .arrow-back::before {
  content: '\2190';
  z-index: 100;
  width: 200px;
  -webkit-transform: translateY(-1rem) !important;
  -ms-transform: translateY(-1rem) !important;
  transform: translateY(-1rem) !important;
  position: absolute;
  cursor: pointer;
}

.spin-fullpage {
  height: 100vh;
  z-index: 100;
}

.spin-fullpage span {
  width: 100px;
}

.onreporting-modal .ant-carousel .slick-dots-bottom {
  bottom: -2rem;
}

.viewing-carousel ul.slick-dots-bottom {
  /* bottom: -0.7rem; */
  -webkit-transform: translate(-12px, 1.6rem);
  -ms-transform: translate(-12px, 1.6rem);
  transform: translate(-12px, 1.6rem);
}

.onreporting-modal .ant-carousel .slick-dots li,
.ant-carousel .viewing-carousel .slick-dots li {
  height: 0.4rem;
  width: 2.4em;
  border: 1px solid lightskyblue;
}

.ant-carousel .viewing-carousel .slick-dots li {
  height: 0.4rem;
  width: 2.4em;
  /* border: 1px solid lightskyblue; */
  border: none;
  background-color: rgba(79, 85, 85, 0.4);
}

.ant-carousel .viewing-carousel .slick-dots li.slick-active {
  background-color: darkslategrey;
  background: darkslategrey;
}

.onreporting-modal .ant-carousel .slick-dots li button,
.ant-carousel .viewing-carousel .slick-dots li button {
  height: 100%;
  border: none;
  border: 1px solid darkslategrey;
  background-color: darkslategrey;
}

.reporting .ant-result-icon {
  margin-top: 15%;
}

.ant-result-icon svg {
  width: 104.8px;
  height: 104.8px;
  -o-object-fit: contain;
  object-fit: contain;
}

.reporting .ant-modal-body .anticon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
}

.reporting .ant-result-warning .ant-result-icon > .anticon {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.reporting.comparing .ant-modal-content {
  /* width: 65.5rem !important;
  height: 40rem; */
  /* -webkit-transform: translate(-15rem, -4rem);
  -ms-transform: translate(-15rem, -4rem);
  transform: translate(-15rem, -4rem);
  */

  width: 1050px !important;
  height: 750px;
}

@media screen and (max-width: 1269px) {
  .reporting.comparing .ant-modal-content {
    transform: translate(-15rem, -5.5rem);
  }
}

.reporting.comparing .ant-modal-body {
  height: 100%;
  max-height: unset;
}

.comparing .facility-report__body.onviewing-modal {
  height: 100%;
}

.facility-report__standard .ant-modal-body {
  /* height: 18rem; */
  /* height: 100%; */
}

.comparing .onviewing-modal {
  height: 100%;
}
.comparing .onviewing-modal .ant-row-start {
  -ms-flex-line-pack: justify;
  align-content: space-between;
  margin-bottom: 0.8rem;
}

.recording .facility-reporting__form {
  margin-top: 1.6rem;
}

.comparing ul.ant-table-pagination.ant-pagination {
  display: none;
}

.compare-accordion {
  /* width: 58rem; 
  height: 48rem;  */
  width: 928px;
  /* height: 600px; */
  /* height: 400px; */
  /* height: 22rem; */
  height: 28rem;
  overflow: scroll;
  background: inherit;
  overflow: scroll;
}

.comparing .ant-collapse-content-box {
  /* overflow-x: scroll; */
}
.comparing .ant-collapse-content-box {
  /* overflow-x: scroll; */
}

.comparing .comparison-table .ant-table-wrapper {
  overflow-x: scroll;
}

.comparing .switch-average-type {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  max-width: unset;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.compare-accordion .ant-collapse-header[aria-expanded='false'] {
  padding: 24px 0;
  /* font-weight: bold; */
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
  padding-left: 0px !important;
}

.compare-accordion .ant-collapse-header[aria-expanded='true'] {
  /* font-size: 12.8px; */
  padding: 12px 0 !important;
  font-weight: bold;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.compare-accordion .ant-collapse-header span {
  /* letter-spacing: 1.6px; */
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}
.compare-accordion .ant-collapse-header span > p {
  /* letter-spacing: 1px; */
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.compare-accordion .ant-collapse-content-box > div:first-child {
  padding: 0 !important;
  /* max-width: 95%; */
}

.comparing .compare-accordion .ant-collapse-content-active {
  position: relative;
}

.compare-accordion ul.ant-table-pagination.ant-pagination {
  display: none;
}

.compare-accordion td.ant-table-cell {
  padding: 12px 16px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}

.compare-accordion thead,
.compare-accordion th,
.compare-accordion th .ant-table-cell {
  color: var(--compare-table-head-color);
  background: var(--compare-table-head-bg);
}

.published.reporting-form__heading thead {
  /* .published.reporting-form__heading th { */
  /* .published.reporting-form__heading .ant-table-cell { */
  color: var(--compare-table-head-color) !important;
  background: var(--compare-table-head-bg) !important;
}

.published.reporting-form__heading th.ant-table-cell {
  font-size: 9.6px;
  font-family: var(--font-family);
  color: var(--compare-table-head-color);
  background: var(--compare-table-head-bg);

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  text-align: center;
}

.published.reporting-form__heading td.ant-table-cell {
  /* padding: 12px 16px; */
  font-family: var(--font-family) !important;
  font-size: 12px;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  background: #ffffff;
  background-color: #ffffff;
  text-align: center;
}

.published.reporting-form__heading .ant-table-row {
  /* font-size: 8.6px; */
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #9b9b9b; */
  color: #ffffff !important;
  font-family: var(--font-family) !important;
  background-color: var(--non-qaqc-highlight);
  font-weight: normal !important;

  /* background: #c0c3cc; */
  border: none;
}

.published.reporting-form__heading tfoot.ant-table-summary tr {
  /* background-color: #2e5bff; */
  padding: 0;
  font-size: 12px;
  background-color: var(--qaqc-highlight);
  font-family: var(--font-family) !important;
  color: var(--dark);
}

.published.reporting-form__heading tfoot.ant-table-summary tr td {
  background-color: var(--qaqc-highlight) !important;
  background: var(--qaqc-highlight) !important;
  font-family: var(--font-family) !important;
  padding: unset !important;
  font-size: 12px;
}

.published.reporting-form__heading tfoot > tr > th,
.published.reporting-form__heading tfoot > tr > td {
  padding: 12px !important;
}

.published.reporting-form__heading tfoot.ant-table-summary tr > th {
  background: var(--qaqc-highlight) !important;
}

.compare-accordion th.ant-table-cell {
  /* font-size: 9.6px; */
  font-family: var(--font-family);
  color: var(--compare-table-head-color);
  background: var(--compare-table-head-bg);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  text-align: center;
}

.compare-accordion tbody.ant-table-body {
  /* text-align: center;
  font-size: 8.8px; */
}

.compare-accordion .ant-table-row {
  /* font-size: 8.6px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #9b9b9b; */
  color: #ffffff;
  background-color: var(--non-qaqc-highlight);
  /* background: #c0c3cc; */
  border: none;
}

.compare-accordion .ant-table-row[data-row-key='0-0'] {
  /* background-color: #2e5bff; */
  background-color: var(--non-qaqc-highlight);
  color: var(--dark);
}
/* .compare-accordion .ant-table-row td:last-child {
  background-color: #ffffff !important;
  background: #ffffff !important;
  color: #ffa97f;
  border: none;
  box-shadow: none;
} */

.compare-accordion .ant-table-row td.ant-table-cell {
  color: var(--compare-table-cell-color);
  background-color: var(--non-qaqc-highlight);
  background: var(--non-qaqc-highlight);
  /* background-color: #585a5f; */
  /* background: #585a5f; */
  /* background: #c0c3cc; */

  /* background-color: rgb(88, 90, 95, 0.7); */
  border: none;
}

.compare-accordion .ant-table-row:not([data-row-key='0-0']):hover > td {
  /* color: #ffffff; */

  background-color: var(--non-qaqc-highlight);
  background: var(--non-qaqc-highlight);
  border: none;
}

.compare-accordion .ant-table-row[data-row-key='0-0'] td.ant-table-cell {
  /* background-color: rgba(84, 100, 160, 0.6) !important; */
  background-color: var(--qaqc-highlight) !important;
  background: var(--qaqc-highlight) !important;
  /* background-color: rgba(85, 108, 192, 1) !important; */
  /* background-color: rgba(63, 86, 170, 0.9) !important; */
  /* background-color: #2e4083 !important; */
  /* background: rgba(46, 91, 255, 0.7) !important; */
  /* background: #2e4083 !important; */
}

/* .compare-accordion .ant-table-row td:last-child.ant-table-cell {
  background-color: #ffffff !important;
  background: #ffffff !important;
  display: flex;
  color: #2c2d2e;
  border: none;
  box-shadow: none;
} */

/* .compare-accordion .ant-table-row td:last-child.ant-table-cell > .compare-avg:after {
  content: '';
  font-size: 8px !important;
  padding: 0.0005rem 0.36rem;
  vertical-align: middle;
  margin-right: 5px;
  
  color: #ffa97f;
  background-color: #ffa97f;
} */

.compare-accordion .ant-table-row td:last-child.ant-table-cell > span:first {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  text-align: left;
  border: none;
  width: 100%;
  margin-right: 1.6px;
  overflow: hidden;
  padding-right: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.compare-accordion .ant-table-row td:last-child .compare-avg {
  width: 24px;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-right: 2.4px;
}

.compare-accordion .ant-table-row td:last-child .compare-avg-above,
.compare-accordion .ant-table-row td:last-child .compare-avg-below {
  content: '';
  font-size: 6.4px !important;
  padding: 0.0004rem 0.288rem;
  vertical-align: middle;
  /* color: #ac000d;
  background-color: #ac000d; */
  text-align: right;
  place-content: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

.compare-accordion .ant-table-row td:last-child.ant-table-cell .compare-avg-above {
  /* color: #00bf00;
  background-color: #00bf00; */
  text-align: right;
}
.compare-accordion .ant-table-row td:last-child.ant-table-cell .compare-avg-below {
  /* color: #ac000d;
  background-color: #ac000d; */
  text-align: right;
}

.compare-accordion .qaqc-underneath:before,
.compare-accordion .cons-underneath:before {
  /* .compare-accordion .average-underneath-a:before, */
  /* .compare-accordion .average-underneath-b:before { */
  content: '';
  font-size: 6.4px !important;
  color: var(--qaqc-highlight);
  background-color: var(--qaqc-highlight);
  padding: 0.0004rem 0.288rem;
  vertical-align: middle;
  margin-right: 4px;
}

.compare-accordion .cons-underneath:before {
  background-color: rgba(151, 156, 168, 0.7);
}

.compare-accordion .average-underneath-a:before {
  /* background-color: #00bf00; */
}
.compare-accordion .average-underneath-b:before {
  /* background-color: #ac000d; */
}

.compare-accordion .switch-average-type {
  /* height: 7.2rem; */
}

.compare-accordion .switch-average-type p {
  font-weight: bold;
  /* letter-spacing: 2px; */
}

.compare-accordion .switch-average-type .ant-radio-group {
  /* margin-top: 0.8rem; */
}

.compare-accordion .switch-average-type .ant-radio-group .ant-radio-wrapper {
  margin-right: unset;
  /* margin-bottom: 0.8rem; */
}

.compare-accordion .switch-average-type .ant-radio-group.ant-radio-group-outline {
  margin-left: 1rem;
}

.compare-accordion .switch-average-type .ant-radio-group .ant-radio-wrapper span.ant-radio + * {
  padding-left: 14.4px;
}

.compare-accordion .qaqc-underneath,
.compare-accordion .average-underneath-a,
.compare-accordion .average-underneath-b,
.compare-accordion .cons-underneath {
  /* font-size: 9.6px; */
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(54, 91, 160, 0.5);
  margin-right: 0.8rem;
  vertical-align: middle;
  font-family: var(--font-family) !important;
}
.compare-accordion .cons-underneath {
  color: var(--non-qaqc-highlight);
}

.compare-accordion .average-underneath-a {
  color: #00bf00;
  margin-right: 0;
}
.compare-accordion .average-underneath-b {
  color: #ac000d;
}

.compare-logo-col {
  overflow: visible !important;
}

.compare-logo {
  height: 120px;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  margin-top: -60px;
  /* margin-left: -60px; */
  font-size: 28.8px;
}
.compare-logo .sq {
  height: 80px;
  width: 80px;
  z-index: 1;
  border: 1px solid #ccc;
  background-color: #ffffff;
  position: absolute;
}

.compare-logo .sq.sq-letter p:before {
  content: '····· ★ ·····';
  vertical-align: middle;
  text-align: center;
  width: 100%;
  /* margin-bottom: 5px; */
  padding: 1.6px 4px;
  display: block;
  font-family: 'LucidaGrande';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #fffffe;
}
.compare-logo .sq.sq-letter p:after {
  content: '⎻⎻⎻⎻';
  vertical-align: middle;
  text-align: center;
  width: 100%;
  margin-bottom: 4px;
  padding: 4px;
  font-weight: bolder;
  display: block;
  font-family: 'LucidaGrande';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.6px;
  word-break: break-all;
  color: #fffffe;
}

.compare-logo .sq.sq-letter {
  /* line-height: 100px; */
  font-size: 19.2px;
  height: 96px;
  width: 96px;
  border-radius: 50%;
  -webkit-transform: translate(-8%, -8%);
  -ms-transform: translate(-8%, -8%);
  transform: translate(-8%, -8%);
  padding: 8px;
  z-index: 100;
  text-align: center;
  border: none;
}

.compare-logo .sq.sq-letter:not(.published) {
  background-color: rgba(221, 66, 75, 1);
}

/* .compare-logo .sq.sq-letter.draft {
  background-color: rgba(221, 66, 75, 1);
} */

.compare-logo .sq.sq-letter.published {
  background-color: rgb(8, 155, 160);
}

.compare-logo .sq.sq-letter p {
  font-family: 'LucidaGrande';
  font-size: 12px;
  /* font-size: 9.6px; */
  margin: 0 auto !important;
  font-weight: normal;
  padding: 4px;
  letter-spacing: 1.6px;
  color: #fffffe;
}

.compare-logo .sq-one {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  background: transparent;
}
.compare-logo .sq-one-2 {
  -webkit-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
}
.compare-logo .sq-two {
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}
.compare-logo .sq-two-2 {
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}
.compare-logo .sq-three {
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}
.compare-logo .sq-three-2 {
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
}
.compare-logo .sq-four {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.compare-logo .sq-four-2 {
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}
.compare-logo .sq-five {
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
.compare-logo .sq-five-2 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.compare-logo .sq-six {
  -webkit-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  transform: rotate(50deg);
}
.compare-logo .sq-six-2 {
  -webkit-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  transform: rotate(55deg);
}
.compare-logo .sq-seven {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.compare-logo .sq-seven-2 {
  -webkit-transform: rotate(65deg);
  -ms-transform: rotate(65deg);
  transform: rotate(65deg);
}
.compare-logo .sq-eight {
  -webkit-transform: rotate(70deg);
  -ms-transform: rotate(70deg);
  transform: rotate(70deg);
}
.compare-logo .sq-eight-2 {
  -webkit-transform: rotate(75deg);
  -ms-transform: rotate(75deg);
  transform: rotate(75deg);
}
.compare-logo .sq-nine {
  -webkit-transform: rotate(80deg);
  -ms-transform: rotate(80deg);
  transform: rotate(80deg);
}
.compare-logo .sq-nine-2 {
  -webkit-transform: rotate(85deg);
  -ms-transform: rotate(85deg);
  transform: rotate(85deg);
}
.compare-logo .sq-ten {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.compare-logo .sq-ten-2 {
  -webkit-transform: rotate(95deg);
  -ms-transform: rotate(95deg);
  transform: rotate(95deg);
}
