.add__sector__button {
    margin-left: 0 !important;
}
.edit__sector__btn {
    border: none !important;
    background: transparent;
}

.sector__lookup__row td:nth-child(3) {
    text-align: right !important;
}
.ant-modal.create__sector__modal {
    height: 300px !important;
    font-family: var(--font-family);
}
.create__sector__modal .ant-modal-title {
    font-family: var(--font-family-bold);
    font-size: 18px;
}
.create__sector__modal .ant-modal-content {
    height: inherit;
    border-radius: 13px;
}

.create__sector__modal .ant-form-item-label label {
    font-weight: 600;
}

.edit__sector__modal {
    height: 300px !important;
    font-family: var(--font-family);
}
.edit__sector__modal .ant-modal-content {
    border-radius: 13px;
    height: inherit;
}
.edit__sector__modal .ant-modal-title {
    font-family: var(--font-family-bold);
}

.edit__sector__modal .ant-form-item-label label {
    font-weight: 600;
}
