/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/* .facility-overflow__menu {
  background-color: '#f4f4f4';
}

.facility-overflow__menu:hover {
  background-color: '#ffffff';
  color: black;
}

.facility-overflow__menu li:hover {
  -webkit-box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px 2px rgba(0, 0, 0, 0.05);
  color: rgb(4, 46, 46);
  background-color: '#ffffff';
} */

.facility-personnel__others {
  /* style the p */
}

.facility-personnel__notaval {
  /* style the not availae personnel */
}

.reports-table thead,
.reports-table tr,
.reports-table th,
.reports-table .ant-table-cell {
  background: white;
  /* padding: 10px 10px; */
}

.ant-table-tbody tr:nth-child(even) {
  background-color: rgba(225, 234, 243, 0.22);
}

.facility-table__popover {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.facility-table__popover .sample-point__popover {
}
.facility-table__popover .address__popover {
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  /* background-color: rgba(225, 234, 243, 0.22); */
  background: unset;
}

.ant-modal-content {
  /* position: sticky; */
  position: fixed;
  /* top: 25vh; */
  /* left: 15vw; */
  /* right: 15vw; */
  width: 32vw;
  height: 56vh;
  /* bottom: 15vh; */
  border-radius: 5px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.facility-header__main .ant-table {
  font-size: 12px !important;
}
