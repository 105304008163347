/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/* .facility-upload__rows:nth-child(even) {
  background-color: rgba(225, 234, 243, 0.22);
} */

.facility-upload__rows .ant-table-cell-fix-left,
.facility-upload__rows .ant-table-cell-fix-right {
  /* background-color: rgba(225, 234, 243, 0.22); */
  /* background: unset; */
}

.facility-upload__rows td.ant-table-cell {
  /* padding: 8px 16px; */
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.entry-date {
  font-family: var(--font-family);
  /* font-size: x-small !important; */
  /* font-size: x-small; */
  /* font-size: smaller; */
  font-size: 14px;
  /* line-height: 1.5715; */
  font-weight: 100;
  word-break: break-all;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.facility-upload__rows .entry-date {
  font-size: 14px;
}

.recording-form__heading .ant-table-thead th,
.reporting-form__heading .ant-table-thead th {
  font-size: x-small;
  /* padding: 6px 16px; */
}

.recording-form__heading .ant-table-wrapper,
.reporting-form__heading .ant-table-wrapper {
  border-left: 1px solid #f0f0f0;
}
.recording-form__heading .ant-table-header,
.reporting-form__heading .ant-table-header {
  border-top: 1px solid #f0f0f0;
}

.reporting-form__heading thead,
.recording-form__heading thead,
.reporting-form__heading tr,
.recording-form__heading tr,
.reporting-form__heading th,
.recording-form__heading th,
.recording-form__heading .ant-table-cell,
.reporting-form__heading .ant-table-cell {
  background: white;
  padding: 10px 10px;
}

.recording-form__heading th.ant-table-cell,
.reporting-form__heading th.ant-table-cell {
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.recording-form__heading tbody.ant-table-body,
.reporting-form__heading tbody.ant-table-body {
  text-align: center;
  font-size: 11px;
}

.reporting-form__heading .ant-table-row,
.recording-form__heading .ant-table-row,
.recording-form__heading .entry-date,
.reporting-form__heading .entry-date {
  /* font-size: 11px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.reporting-form__heading .report-average,
.recording-form__heading .report-average,
.recording-form__heading .report-average > td,
.reporting-form__heading .report-average > td {
  /* text-align: center; */
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.reporting-form__heading .report-average-2 {
  /* table-layout: fixed; */
  /* padding: 10px 10px; */
  /* display: table-header-group; */
}
