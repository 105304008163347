.signup__form {
    font-family: var(--font-family);
    margin-bottom: 4rem;
}
.form__header {
    margin-bottom: 40px;
    /* font-family: var(--font-family-bold); */
}
.form__header h1 {
    font-size: 36px;
}
.form__header p {
    color: #828ea9;
    font-size: 16px;
}
.error-message {
    color: red;
    font-weight: 700;
    font-size: 12px;
}
.signup__form .ant-form-item-label > label {
    color: #5a7184;
    font-weight: 600;
    font-size: 15px;
}
.form-group.exempt label {
    font-weight: 600;
    font-family: var(--font-family);
}
.signup__form .ant-form-item-required::before {
    display: none !important;
}
.signup__form .ant-form-item-explain {
    margin-top: 0;
}
.signup__form .ant-input-group {
    /* border: 1px solid #c3cad9 !important; */
    border-radius: 5px !important;
    width: 100%;
    transform: none;
    height: 41px;
}

.signup__form .ant-input-group .ant-input-group-addon {
    background: transparent;
}

.phonenumber__input .ant-input-group-addon:first-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.phonenumber__input .ant-input {
    height: 41px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.signup__input {
    height: 41px;
}
/* .ant-input-number {
    height: 56px;
    display: block;
    width: 100%;
    padding: 12px 20px;
    border-radius: 8px;
} */

.ant-input-password.signup__input {
    max-height: 41px;
}
.ant-input-affix-wrapper.ant-input-password.signup__input {
    border-radius: 5px;
}
.ant-input-password.signup__input > .ant-input {
    height: 35px;
}
.signup__input .ant-select-selector {
    height: 41px !important;
    border-radius: 5px !important;
}

/* CONFIRMATION MODAL */

.signup__modal {
    background: #fff;
    width: 600px !important;
    height: 400px;
    font-family: var(--font-family);
    text-align: center;
}

.signup__modal .ant-modal-content {
    height: inherit;
    width: inherit;
    position: sticky !important;
}

.signup__modal h3 {
    font-size: 18px;
    margin-top: 2rem !important;
}
.signup__modal p {
    color: #828ea9;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2rem !important;
}
.signup__modal img {
    width: 200px;
}
.signup__modal .footer {
    width: 100%;
    text-align: center;
}

.signup__modal a {
    color: #0c5bab !important;
}
.loader-container {
    text-align: center;
}

/*** SIGNUP 404 ***/
.signup__notfound {
    background: #fff;
    font-family: var(--font-family);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}
.signup__notfound h1 {
    font-family: var(--font-family-bold);
    color: #2e384d;
}
.signup__notfound p {
    color: #828ea9;
}
.form-group.signup__footer a {
    font-weight: 600;
    color: #0c5bab !important;
}
.form-group.signup__footer p {
    font-weight: 600;
}
/* .ant-checkbox + span {
    color: #828ea9;
    font-size: 14px;
}
.ant-checkbox-checked {
    &:after {
        border: 1px solid #0c5bab;
    }
}
.ant-checkbox-checked .ant-checkbox-inner {
    background: #0c5bab;
    border-color: #0c5bab;
}
.ant-input-number: focus, .ant-input-number-focused {
    border-color: #0c5bab;
    box-shadow: none;
}
.ant-input-affix-wrapper {
    &:hover {
        border-color: #0c5bab;
    }
    &:focus {
        border-color: $0c5bab;
        box-shadow: none !important;
        outline: none;
    }
}
.ant-input-suffix {
    display: flex;
    align-items: center;
}
.ant-input-password {
    border-radius: 8px;
} */
