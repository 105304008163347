.user__header__main {
  /* padding: 24px 30px; */
  max-width: 100%;
  /* margin-bottom: 14px; */
}

.user__header__main .ant-row-end {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.user__header__main .ant-select-selector {
  height: 32px !important;
  border-radius: 5px;
  font-family: var(--font-family);
}
.user__header__main .ant-select-selector .ant-select-selection-placeholder {
  line-height: 30px;
  font-size: 14px;
  font-family: var(--font-family);
}
.user__header__main .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 32px;
}
.users__tab .ant-tabs-nav {
  font-family: var(--font-family);
  font-size: 14px;
  color: #2e384d;
}

.ant-select-item-option-content {
  font-family: var(--font-family);
}

.empty__users {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  font-family: var(--font-family);
}
.empty__users .users__icon {
  width: 200px;
  height: 200px;
  opacity: 0.2;
}
.empty__users .content {
  width: 100%;
  text-align: center;
}
.empty__users .content h3 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 2rem !important;
}
.empty__users .content p {
  color: #828ea9;
  font-size: 16px;
  margin-top: 0.5rem !important;
}

.users__tab {
  margin-top: 2rem;
}
.users__tab .ant-tabs-tab-active.ant-tabs-tab {
  color: #0c5bab;
}
.users__tab .ant-tabs-ink-bar {
  background-color: #0c5bab;
}
.update__action {
  display: block;
  z-index: 100;
  position: relative;
  padding: 5px;
}
.users__loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-user-btn button {
  height: 32px;
  font-family: var(--font-family);
  padding: 0 1rem;
}
.user-overflow__menu {
  font-family: var(--font-family);
}
.user-table__rows {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}
.create-user-btn button {
  height: 32px;
  margin-left: 0;
}
.regulator__input.ant-select {
  width: 100%;
}
