.update__user__modal {
  width: 900px !important;
  min-height: 600px !important;
  height: auto;
  font-family: var(--font-family);
  margin-bottom: 3rem;
}
.update__user__modal .ant-modal-content {
  width: inherit;
  height: inherit;
  padding: 3rem 2rem;
  position: sticky !important;
}
.update__user__modal .ant-modal-header {
  border-bottom: none;
}
.update__user__modal .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
}
.update__user__modal .update__user__tabs {
}
.update__user-form .ant-select-selection-item-content {
  font-weight: 600;
}
.update__user-form .form-group {
  margin-bottom: 1.3rem;
}

.update__user-form .form-group label {
  font-weight: 600;
}

.ant-select-item {
  font-size: 14px !important;
}
.update__user-form .ant-select-selection-placeholder {
  font-size: 14px;
}

.update__user-form .ant-checkbox-group-item + .ant-checkbox-group-item {
  display: block;
}
.update__user-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
}
.update__user-form .ant-checkbox-wrapper {
  margin-bottom: 17px;
  display: block;
}
.update__user-form .ant-checkbox-wrapper > span {
  color: #828ea9;
  font-weight: normal;
  text-transform: capitalize;
}
.update__user-form .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.update__user-form .save__btn {
  background: #0c5bab;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 10px;
  padding: 0.5rem 1.5rem;
  margin-top: 2rem;
}
.update__user-form .cancel__btn {
  background: transparent;
  color: #005bab;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1.5rem;
  margin-top: 2rem;
  border: 1px solid;
}
.update__user-form .error-text {
  text-align: center;
  font-size: 16px;
}
.update__user-form .ant-form-item {
  width: 100%;
}
.update__user-form .ant-form-item-explain {
  margin-top: 0;
}

.update__user__modal .update__unavailable {
  text-align: center;
}
.update__unavailable svg {
  opacity: 0.2;
  width: 100px;
  height: 100px;
}
.update__unavailable h3 {
  font-weight: 600;
  margin-top: 1rem !important;
}
.update__unavailable button {
  background: #0c5bab;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 0.7rem 2rem;
  border-radius: 5px;
  margin-top: 1rem;
}
.update__user-form label {
  color: #2e384d;
}
.update__user-form .ant-form-item-label label {
  font-family: var(--font-family);
  font-weight: 600;
  color: #2e384d;
}
.update__user-form .ant-select-multiple .ant-select-selection-item {
  background: #e7f5ff;
  border-radius: 10px;
  border: none;
  font-size: 12px;
  color: #0c5bab;
  padding: 5px 5px 0 10px;
  height: 30px;
}
.area__badge {
  background: #e7f5ff;
  color: #0c5bab;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}
.delete__area {
  border: none;
  color: #0c5bab;
  border-radius: 4px;
  background: transparent;
}
.user__area {
  margin-bottom: 1rem;
}
.area__title {
  margin-bottom: 1rem !important;
  font-weight: 600;
}

.profile__heading {
  font-weight: 600;
  color: #2e384d;
}
.profile__text {
  color: #828ea9;
  margin-bottom: 1rem !important;
}
.area__summary__list {
  list-style-type: disc;
}
.area__summary__list li {
  font-weight: 600;
}
.add__btn {
  background: #0c5bab;
  color: #fff;
  font-weight: 600;
  font-weight: 16px;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  border: none;
}
.delete__btn {
  color: #db3236;
  font-weight: 600;
  font-weight: 16px;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  border: none;
  background: transparent;
}
.delete__btn:focus {
  border: 1px solid #db3236;
  outline-color: transparent;
}

.add__more__container {
  /* margin-top: 2rem; */
}
.delete__areas__container {
  /* margin-top: 2rem; */
}
.add__more__container .ant-select-selection-placeholder {
  line-height: 40px !important;
}

.area__container {
  margin-top: 1.5rem;
}
.update__user__modal .close__btn {
  margin-top: 2rem;
  border: none;
  padding: 0.5rem;
  color: #2e384d;
  background: transparent;
  font-weight: 600;
  border-radius: 5px;
}
.areas__heading {
  font-weight: bold;
}
.ant-tabs.update__user__tabs .ant-tabs-nav .ant-tabs-tab{
  text-align: left;
}