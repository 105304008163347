/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

p {
  margin-bottom: unset !important;
}

.main-page__body {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.active,
.not-active {
  text-transform: capitalize;
  width: 64px;
  height: 16px;
  color: var(--active-green);
}

.qaqc,
.not-qaqc {
  text-transform: capitalize;
  width: 64px;
  height: 16px;
}

.not-active {
  color: var(--inactive-red);
}

.loading-outlined svg {
  width: 100%;
  margin: auto;
}

.hide {
  display: none;
}

.nocursor {
  cursor: not-allowed;
  border: none;
  opacity: 0.2;
  background-color: var(--no-cursor-card) !important;
  z-index: 100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nocursor .qtr-icon img {
  -webkit-filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1);
  filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1);
}

.opacity-7-10 {
  opacity: 0.5 !important;
}

.qtr-icon {
  margin-bottom: 0.4rem;
}

.qtr-icon img {
  width: 52px;
  height: 52px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-top: 1rem;
}

.create-fac-btn button,
.create-user-btn button {
  margin-left: 0px !important;
  /* height: 30px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white); */
}

.ant-modal-close {
  display: none;
}

.ant-modal-body {
  height: 100%;
  width: 100%;
}

#facilities .ant-steps-item-process .ant-steps-item-icon {
  background-color: #007ace;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 8px 14px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #393939;
}

.ant-dropdown-menu-item:hover {
  background-color: #f4f4f4;
}

.facility-header__main div.ant-select:not(:last-of-type) {
  margin-right: 10px;
}

.ant-select-item {
  font-size: 0.75em;
  padding: 2px 8px;
}

.ant-select-dropdown {
  font-weight: normal;
  font-family: var(--font-family);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-dropdown .ant-select-item.ant-select-item-option,
.ant-select-item.ant-select-item-option-content {
  font-weight: normal;
  font-family: var(--font-family);
  align-items: center !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-item-option-content {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.ant-select-item.ant-select-item-option:not(:first-child) {
  font-weight: normal;
  font-family: var(--font-family);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-selection-placeholder {
  font-weight: normal !important;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 14.4px;
  font-weight: normal !important;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  color: var(--dark);
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  /* line-height: 24px; */
  font-weight: normal !important;
  font-family: var(--font-family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  color: var(--dark);
}

.facility-header__main.reports-head {
  margin-bottom: unset !important;
}

.facility-header__main .ant-input,
.user__header__main .ant-input,
.user-header__main .ant-input {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.facility-header__main .ant-input-affix-wrapper,
.user__header__main .ant-input-affix-wrapper,
.user-header__main .ant-input-affix-wrapper {
  height: 32px;
  width: 254px;
}
.facility-header__main .ant-input-affix-wrapper > input.ant-input,
.user__header__main .ant-input-affix-wrapper > input.ant-input,
.user-header__main .ant-input-affix-wrapper > input.ant-input {
  padding-left: 1em !important;
}

.facility-header__main .ant-row-end {
  margin-left: 0px !important;
  margin-right: 0px;
}

.facility-header__main .ant-row-end .ant-col,
.user__header__main .ant-row-end .ant-col,
.user-header__main .ant-row-end .ant-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.facility-table ul.ant-table-pagination li.ant-pagination-item,
.facility-table ul.ant-table-pagination li.ant-pagination-prev,
.facility-table ul.ant-table-pagination li.ant-pagination-next,
.reports-table ul.ant-table-pagination li.ant-pagination-prev,
.reports-table ul.ant-table-pagination li.ant-pagination-next,
.reports-table ul.ant-table-pagination li.ant-pagination-item,
.users-table ul.ant-table-pagination li.ant-pagination-prev,
.users-table ul.ant-table-pagination li.ant-pagination-next,
.users-table ul.ant-table-pagination li.ant-pagination-item {
  height: 24px;
  width: 25px;
  line-height: 24px;
  border: none;

  /* line-height: 24px;
  width: 25px;
  height: 24px;
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a6872; */
}

.facility-table ul.ant-table-pagination .ant-pagination,
.users__tab ul.ant-table-pagination .ant-pagination,
.reports-table ul.ant-table-pagination .ant-pagination,
.users-table ul.ant-table-pagination .ant-pagination {
  font-size: 14px;
  border: none;
}

li.ant-pagination-item-active {
  background-color: #007ace !important;
  font-family: IBMPlexSans;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white) !important;
}

ul.ant-pagination li.ant-pagination-prev .ant-pagination-item-link,
ul.ant-pagination li.ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.ant-tree {
  font-size: 12px;
}

.ant-tree .ant-tree-treenode {
  font-size: 10px;
  font-weight: normal;
}

.facility-report__body .anticon-loading svg {
  width: 100%;
}

/* .ant-modal-content {
  zoom: 80%;
} */
/* .facility-report__standard .ant-modal-content {
  zoom: 80%;
} */

.facility-report__standard .ant-modal-content {
  border-radius: 5px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  position: sticky !important;
}

.facility-table .ant-table-body,
.reports-table .ant-modal-body {
  overflow: unset !important;
}

/* .facility-overflow-menu, .ant-dropdown-menu {

} */

.table-hash-count,
.reporting-form__heading span.table-hash-count {
  display: inline-block;
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.facility-report__standard .facility-report__header .report-title {
  /* letter-spacing: 2px; */
  font-family: var(--font-family-bold);
  font-size: 23px;
  /* font-size: 25px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #4a4a4a;
}

.facility-report__standard .facility-report__header .report-title,
.facility-report__standard .facility-report__header .report-subtitle-1,
.facility-report__standard .facility-report__header .report-date__title,
.facility-report__standard .facility-report__header .report-subtitle-2 {
  margin-bottom: 10px !important;
}

.facility-report__standard .facility-report__header .report-subtitle-1 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: right; */
  color: #9b9b9b;
}

.facility-report__standard .facility-report__header .report-subtitle-2 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: right; */
  color: #9b9b9b;
}

.facility-report__standard .facility-report__header .report-date__title {
  font-family: var(--font-family);
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  color: #4a4a4a;
}

.facility-report__standard .facility-report__header .report-time {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}

.thereport__body,
.facility-report__body:not(.onviewing-modal) {
  position: relative;
  margin-top: 10px;
  /* margin-top: 26px; */
  margin-bottom: 88px;
}
.facility-report__body.onviewing-modal {
  height: 100%;
}

@media screen and (max-width: 1469px) {
  .facility-report__body {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
}
.thereport__body .ant-card,
.facility-report__body .ant-card {
  width: 260px;
  height: 200px;
  border-radius: 5px;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  background-color: var(--white);

  opacity: 1;
  /* box-shadow: 0px 4px 2px -4px rgba(0, 0, 0, 0.4); */
  /* -webkit-box-shadow: 0px 4px 2px -4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 2px -4px rgba(0, 0, 0, 0.4); */
  background-color: var(--white);
}

@media screen and (max-width: 1469px) {
  .thereport__body .ant-card,
  .facility-report__body .ant-card {
    width: 11.2rem !important;
    height: 9.8rem !important;
    box-shadow: none;
  }
  .thereport__body div.ant-row,
  .facility-report__standard div.ant-row {
    margin-right: unset !important;
  }
}

.thereport__body .ant-card:not(.nocursor):hover,
.facility-report__body .ant-card:not(.nocursor):hover {
  -webkit-box-shadow: -20px -15px -29px 8px rgba(67, 90, 111, 0.47);
  box-shadow: -20px -15px -29px 8px rgba(67, 90, 111, 0.47);
  background-color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid #4a90e2;
}

.thereport__body > .ant-card-body,
.facility-report__body > .ant-card-body,
.facility-card__body > .ant-card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  overflow: hidden;
  height: 100%;
}

.thereport__body .card-lock,
.facility-report__body .card-lock {
  width: 100%;
  text-align: right;
  text-align: -webkit-right;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
}

.facility-report__body .qtr-lock-icon {
  width: 100%;
  text-align: right;
  text-align: -webkit-right;
}

.thereport__body .qtr-lock-icon,
.facility-report__body .qtr-lock-icon img {
  width: 22px;
  height: 24px;
}

@media screen and (max-width: 1469px) {
  .thereport__body .qtr-lock-icon,
  .facility-report__body .qtr-lock-icon img {
    width: 14px;
    height: 16px;
  }
  .qtr-text {
    font-size: 1em !important;
    margin-bottom: 0.4rem !important;
  }
  .qtr-icon img {
    width: 40px !important;
    height: 40px !important;
    -o-object-fit: contain !important;
    object-fit: contain;
    margin-top: 0.8rem !important;
  }
  .qtr-month {
    font-family: var(--font-family);
    font-size: 1rem !important;
  }
}

.facility-card__body .card-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  justify-self: flex-end;
}

.onreporting-modal .facility-card__body .card-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
  justify-self: unset;
}

.qtr-text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-family: var(--font-family);
  line-height: 1.39;
  margin-bottom: 8px;
  /* margin-bottom: 0.4rem; */
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.qtr-month {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #9b9b9b;
}

.onreporting-modal .qtr-text {
  height: 26.4px;
  font-family: var(--font-family);
  line-height: 1.3;
  overflow: hidden;
}

.qtr-subtext {
  /* margin-bottom: 0.4rem; */
  /* opacity: 0.8; */
  font-family: var(--font-family);
  font-size: 11.2px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.facility-report__standard .ant-modal-header {
  padding: 0;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
}

.facility-report__standard .ant-modal-body {
  /* height: 14.4rem; */
  height: calc(100% - 126px);
  padding-top: 24px;
  padding-right: 38px;
  background-color: rgba(10, 10, 120, 0.05);
}

.facility-report__standard .ant-modal-title {
  padding: 13px 38px;
}

.thereport__body div.ant-row,
.facility-report__body:not(.onviewing-modal) div.ant-row {
  margin-right: unset;
  padding-left: 15px;
  margin-top: 50px;
}

.thereport__body img,
.facility-report__body img {
  vertical-align: unset;
}

.facility-report__body.onreporting-modal {
  margin-bottom: unset;
}

.facility-report__body.onreporting-modal .ant-card {
  width: 245px;
  height: 200px;
  border-radius: 5px;
}
.facility-report__body.onreporting-modal .qtr-subtext {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
}
.facility-report__body.onreporting-modal .qtr-text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: bold;
  line-height: 0.95;
  padding-bottom: 15px;
}
.facility-report__body.onreporting-modal .qtr-progress {
  padding-bottom: 29px;
}
.facility-report__body.onreporting-modal .qtr-icon {
  margin-bottom: 20px;
}
@media screen and (max-width: 1469px) {
  .facility-report__body.onreporting-modal .qtr-icon {
    margin-bottom: 5px !important;
  }
  .facility-report__body.onreporting-modal .qtr-progress {
    padding-bottom: 0px;
  }
}

.ant-modal-wrap {
  /* overflow: unset !important; */
}

.ant-result-title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
  margin: 16px;
  color: var(--result-title);
}

.ant-result-subtitle {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  margin: 16px;
  color: var(--result-subtitle);
}
.ant-result-title,
.ant-result-subtitle {
  font-family: var(--font-family);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ant-modal-content ::-webkit-scrollbar,
.ant-modal-wrap ::-webkit-scrollbar {
  width: 15px !important;
  opacity: 0.3;
}

.ant-modal-content ::-webkit-scrollbar :hover,
.ant-modal-content ::-webkit-scrollbar :active,
.ant-modal-content ::-webkit-scrollbar :focus,
.ant-modal-wrap ::-webkit-scrollbar :focus,
.ant-modal-wrap ::-webkit-scrollbar :hover,
.ant-modal-wrap ::-webkit-scrollbar :active {
  /* background: #888 !important; */
}

/* Track */
.ant-modal-content::-webkit-scrollbar-track,
.ant-modal-wrap::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

/* Handle */
/* .ant-modal-content::-webkit-scrollbar-thumb,
.ant-modal-wrap::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* .ant-modal-content::-webkit-scrollbar-thumb:hover,
.ant-modal-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.ant-collapse-borderless,
.ant-collapse {
  background: var(--white) !important;
}

/* custom styles for table header */
.ant-table-thead .ant-table-cell {
  font-weight: bold;
  background-color: #ffffff;
}

/* make all even table rows have a distinct background */
.ant-table-tbody > tr.ant-table-row:nth-child(even) {
  background-color: #f5f7ff;
}

/* The hover state should match the background color set above */
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f5f7ff;
}

/* Make table scroll horizontal when on mobile */
.ant-table {
  overflow-x: auto;
}

.ant-result-title {
  font-size: 23px !important;
  font-weight: bold !important;
}

.ant-result-subtitle {
  font-size: 16px !important;
}

.fac-breadcrumb .ant-row > div {
  margin-left: 0px;
  padding-left: 32px;
}

.profile-section .ant-form-item-control-input,
.password-section .ant-form-item-control-input {
  margin-bottom: var(--input-mb);
}

.password-section .ant-form-item-has-error {
  margin-bottom: 24px;
}

/* .password-section .ant-form-item-has-error .ant-input,
.password-section .ant-form-item-has-error .ant-input-affix-wrapper,
.password-section .ant-form-item-has-error .ant-input:hover,
.password-section .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background: transparent !important;
} */

.filter-popover .ant-popover-content {
  width: max-content !important;
  height: unset !important;
  max-width: unset !important;
  color: var(--dark);
  border-radius: 5px;
  overflow-y: unset !important;
}

.filter-popover .ant-popover-inner {
  border-radius: 5px;
}
.filter-popover .ant-popover-title {
  font-family: var(--font-family-bold);
  font-size: 23px;
  padding-left: 48px;
}

.filtering-search {
  border-radius: 5px;
  width: 30rem;
}

.filtering-search > .ant-row:first-of-type {
  justify-content: space-between;
}
.filtering-search > .ant-row:first-of-type {
  padding: 0px 32px;
}
.filtering-search > .ant-row:last-of-type {
  margin-bottom: 1rem;
}

.filtering-search .ant-form-item-label {
  max-width: 100%;
}

.filtering-search > .ant-row:first-of-type .ant-col {
  max-width: unset;
}
.filtering-search > .ant-row:first-of-type .ant-col:nth-of-type(odd) {
  margin-right: 1rem;
  /* margin-right: 20px; */
}

/* .filtering-search .ant-select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 30px !important;
} */

.filter-icon {
  margin-left: 10px;
}

.filter-icon svg {
  transform: scale(1.5);
}

.filter-icon svg:hover {
  transform: scale(1);
}

.filter-active svg {
  color: var(--primary);
}

.clear-filter {
  text-align: right;
  color: var(--primary);
  padding-right: 32px;
  cursor: pointer;
}

.clear-filter:hover {
  opacity: 0.8;
}

.schedule__heading .ant-col.text-left {
  max-width: 100%;
  flex-basis: unset;
}

.lga__lookup__form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}

.comparison-table .ant-table-body {
  overflow: auto !important;
}

td a.ant-dropdown-link svg {
  font-family: var(--font-family-bold) !important;
  font-size: 16px;
  fill: black;
}
td a.ant-dropdown-link {
  /* padding-top: 5px !important; */
  /* padding-bottom: 5px !important; */
  display: block;
  padding: 5px;
  position: relative;
  z-index: 100;
}

.clickable-breadcrumb {
  color: #0f62fe !important;
  /* padding-right: 5px;
  font-size: 14px; */
}

.measurement__container .padded-data-table thead.ant-table-thead tr th.ant-table-cell {
  background: var(--regular-table-header-bg);
}
